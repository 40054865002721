<template>
  <div>
      <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
        <b-navbar-brand href="#" style="color: #fff;">
            <img :src="brandImage" class="logo" />
            Change Password</b-navbar-brand>
      </b-navbar>
      <b-container style="max-width: 800px;">
          <b-card class="mt-3 p-4" no-body>
            <b-card-text>
              <ChangePassword :loginToken="loginToken" :onPasswordChanged="changePasswordHandler" :reqs="unFulfilledRequirements" />
            </b-card-text>
          </b-card>
      </b-container>
  </div>
</template>

<script>
  import jwt_decode from 'jwt-decode'

  import ChangePassword from './ChangePassword'

  import { API } from '../requests/api-cache'
  import { getLoginRedirect } from '../utils/helpers'
  import { SESSION } from '../utils/session'


  export default {
    name: 'PasswordRequirements',
    components: {
      ChangePassword
    },
    data() {
      return {
        appName: 'scorpion.caveon.com',
        loginToken: '',
        unFulfilledRequirements: {}
      }
    },
    async created() {
      this.loginToken = this.$route.query.loginToken

      try {
        const { statuses } = jwt_decode(this.loginToken)

        this.unFulfilledRequirements = statuses.unFulfilledRequirements
      } catch (error) {
        this.$router.replace({ path: '/login' })
      }
    },
    methods: {
      async changePasswordHandler(passwordData) {
        const result = await API.sendLogin({ loginToken: this.loginToken, password: passwordData.newPassword })
        if (result.error) {
          this.$bvToast.toast('Failed to login! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })
          return
        }

        const url = await getLoginRedirect(result.data)

        return this.$router.push({
          path: url
        })
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      }
    }
  }
</script>

<style scoped>
  .underline {
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid #efefef;
  }
  .margin {
    margin-left: 10px;
  }
</style>
