<template>
  <div>
    <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
      <b-navbar-brand href="#" style="color: #fff;">
        <img :src="brandImage" class="logo" />
        Confirm Two Factor</b-navbar-brand>
    </b-navbar>
    <b-container style="max-width: 950px;">
      <b-card class="mt-3 p-4" no-body>
        <b-card-text>
          <b-form @submit.prevent="submitCodeHandler" novalidate autocomplete="off">
            <b-form-group label="Two factor authentication code *" label-for="code" class="formText">
                <b-form-input
                  autofocus
                  id="code"
                  required
                  type="text"
                  v-model="code"
                  autocomplete="off"
                  style="text-transform:uppercase"
                />
                <b-form-checkbox
                  id="isTrustedDevice-input"
                  v-model="isTrustedDevice"
                  class="mt-2"
                  plain
                >
                  Trust this device in the future
                </b-form-checkbox>
                <b-button
                  v-if="confirmedPhone"
                  type="button"
                  variant="link"
                  size="sm"
                  @click="sendBackupCodeHandler"
                  class="px-0 py-3"
                  >Resend security code to phone</b-button
                >
            </b-form-group>
            <b-row class="mt-3">
              <b-col>
                <b-button
                  block
                  :disabled="!validForm || loading"
                  type="submit"
                  :style="{ background: brandColor, borderColor: 'transparent' }"
                  cols="1"
                  ><b-spinner v-if="loading" label="Loading" small></b-spinner>
                  &nbsp;Submit</b-button
                >
              </b-col>
              <b-col>
                <b-button block @click="cancel" type="button" variant="white" cols="1"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </b-form>
          <br />
        </b-card-text>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { HTTP } from '../requests/requests'
  import { getLoginRedirect } from '../utils/helpers'
  import { SESSION } from '../utils/session'

  import jwt_decode from 'jwt-decode'

  async function submitCode(code, loginToken, isTrustedDevice) {
    try {
      const response = await HTTP.post('/api/auth/confirm_two_factor', {
        code,
        loginToken,
        isTrustedDevice
      })

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function sendBackupCode(loginToken) {
    try {
      const response = await HTTP.post('/api/auth/backup_code', { loginToken })

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ConfirmTwoFactor',
    data() {
      return {
        isQrCode: false,
        code: '',
        loginToken: '',
        loading: false,
        appName: 'scorpion.caveon.com',
        isTrustedDevice: false,
        confirmedPhone: false
      }
    },
    async created() {
      this.loginToken = this.$route.query.loginToken

      try {
        const decoded = jwt_decode(this.loginToken)

        this.confirmedPhone = decoded.statuses.confirmedPhone
      } catch (error) {
        this.$router.replace({ path: '/login' })
      }
    },
    methods: {
      async submitCodeHandler() {
        this.loading = true
        const responseData = await submitCode(this.code, this.loginToken, this.isTrustedDevice)

        if (responseData.error) {
          this.code = ''
          this.loading = false
          console.error(responseData.error)
          this.$bvToast.toast('Failed to verify auth code! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            autoHideDelay: 2500,
            noCloseButton: true
          })
          return
        }

        const { loginToken } = responseData

        const { error, data } = await API.sendLogin({ loginToken })
        if (error) {
          this.$bvToast.toast('Failed to login! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })
          this.loading = false
          return
        }

        const url = await getLoginRedirect(data)
        this.loading = false

        return this.$router.push({
          path: url
        })
      },
      async sendBackupCodeHandler() {
        const responseData = await sendBackupCode(this.loginToken)

        if (responseData.error) {
          this.code = ''
          console.error(responseData.error)
          return
        }

        this.$bvToast.toast('Security code has been sent to your phone.', {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2500,
          noCloseButton: true,
          solid: true
        })
      },
      cancel() {
        this.$router.push({ path: '/' })
      }
    },
    computed: {
      validForm() {
        return this.code && this.code.length === 6 && !this.loading
      },
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      }
    }
  }
</script>

<style scoped>
  .underline {
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid #efefef;
  }
</style>
