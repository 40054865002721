<template>
    <b-modal id="change-phone-modal" :title="modalTitle" @hidden="onModalHidden">
        <template #modal-footer="{ cancel }">
            <b-button @click="cancel" variant="white">Cancel</b-button>

            <b-button v-if="confirm" @click="submitCode" :disabled="loading || !code" :style="{ background: brandColor, borderColor: 'transparent' }">
                Submit
            </b-button>

            <b-button v-else @click="savePhone" :disabled="loading || !phone" :style="{ background: brandColor, borderColor: 'transparent' }">
                Save
            </b-button>
        </template>

        <b-form-group v-if="confirm" label="Code">
            <template #description>
                Didn't receive a code? <span @click="sendCode" class="resend-code">Click here to send another one.</span>
            </template>

            <b-form-input autofocus placeholder="Enter Confirmation Code" @input="onCodeChange" :value="code" />
        </b-form-group>

        <b-form-group v-else label="Phone" :description="changePhoneDescription">
            <b-form-input autofocus placeholder="Enter Phone Number" v-model="phone" />
        </b-form-group>
    </b-modal>
</template>

<script>
import { API } from '../requests/api-cache'
import { EVENT } from '../utils/eventBus'
import { HTTP } from '../requests/requests'
import { SESSION } from '../utils/session'

async function savePhoneRequest(phone) {
    try {
        const url = '/api/users/phone'
        const payload = { phone }
        const response = await HTTP.put(url, payload)
        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function submitCodeRequest(code) {
    try {
        const url = '/api/users/confirm_phone'
        const payload = { code }
        const response = await HTTP.post(url, payload)
        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

async function sendCodeRequest() {
    try {
        const url = '/api/users/send_phone_verification_code'
        const response = await HTTP.get(url)
        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

export default {
    name: 'ChangePhone',
    props: {
        user: {
            type: Object
        }
    },
    data() {
        return {
            code: '',
            phone: '',
            confirm: false,
            loading: false
        }
    },
    created() {
        EVENT.$on('verify-phone', this.onVerifyPhone)

        this.phone = this.user.phone
    },
    beforeDestroy() {
        EVENT.$off('verify-phone', this.onVerifyPhone)
    },
    methods: {
        onModalHidden() {
            this.code = ''
            this.phone = this.user.phone
            this.confirm = false
        },
        onCodeChange(value) {
            this.code = value.toUpperCase()
        },
        async savePhone() {
            this.loading = true

            const { data, error } = await savePhoneRequest(this.phone)

            this.loading = false

            if (error) {
                const globalAlertData = {
                    message: 'Failed to save.',
                    variant: 'danger'
                }

                return EVENT.globalAlert(globalAlertData)
            }

            API.updateMe('phone', data.phone)

            const updatedUser = API.updateMe('phone_confirmed_at', data.phone_confirmed_at)

            EVENT.setGlobalUser(updatedUser)

            this.confirm = true
        },
        async submitCode() {
            this.loading = true

            const { data, error } = await submitCodeRequest(this.code)

            this.loading = false

            if (error) {
                const globalAlertData = {
                    message: 'Failed to verify code.',
                    variant: 'danger'
                }

                return EVENT.globalAlert(globalAlertData)
            }

            const updatedUser = API.updateMe('phone_confirmed_at', data.phone_confirmed_at)

            EVENT.setGlobalUser(updatedUser)

            const globalAlertData = {
                message: 'Phone has been verified.',
                variant: 'success'
            }

            EVENT.globalAlert(globalAlertData)

            this.$bvModal.hide('change-phone-modal')
        },
        async sendCode() {
            if (this.loading) return

            this.loading = true

            const { error } = await sendCodeRequest()

            this.loading = false

            let message = 'A confirmation message has been sent to your phone.'
            let variant = 'success'

            if (error) {
                message = 'Failed to send code.'
                variant = 'danger'
            }

            const globalAlertData = {
                message,
                variant
            }

            EVENT.globalAlert(globalAlertData)
        },
        onVerifyPhone() {
            this.sendCode()

            this.confirm = true

            this.$bvModal.show('change-phone-modal')
        }
    },
    computed: {
        brandColor() {
            return SESSION.branding.color
        },
        modalTitle() {
            return this.confirm ? 'Confirm Phone' : 'Change Phone'
        },
        changePhoneDescription() {
            let twoFactorAuthenticationChange = ''

            if (this.user.enable_multi_factor_auth && this.user.use_sms_for_two_factor) {
                twoFactorAuthenticationChange = ' and SMS two factor authentication will be disabled'
            }

            return `You will receive a confirmation message to this number${ twoFactorAuthenticationChange }.`
        }
    }
}
</script>

<style scoped>
.resend-code:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>
