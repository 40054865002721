<template> 
  <b-form @submit.prevent="save" novalidate class="mt-3">
    <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Organization Name" label-for="organization-name">
        <b-form-input
          autofocus
          id="organization-name"
          required
          type="text"
          v-model="editing.name"
        />
    </b-form-group>
    <!-- TODO more settings to come -->

    <b-tabs content-class="mt-3">
      <b-tab title="Caveon ID authenticated accounts" active>
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Prevent users from using old passwords" label-for="isPreventOldPasswords">
          <b-checkbox name="isPreventOldPasswords" v-model="editing.settings.is_prevent_old_passwords" inline plain></b-checkbox>
        </b-form-group>
      
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Password length requirement" label-for="pw_length">
          <b-form-input
            name="pw_length"
            type="number"
            v-model="editing.settings.pw_length"
          />
        </b-form-group>
    
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Password life (days)" label-for="pw_life">
          <b-form-input
            name="pw_life"
            type="number"
            v-model="editing.settings.pw_life"
          />
        </b-form-group>
    
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Password complexity" label-for="pw_complexity">
          <b-form-select
            :options="pwComplexityOptions"
            name="pw_complexity"
            v-model="editing.settings.pw_complexity"
          />
        </b-form-group>
      </b-tab>

      <b-tab title="All accounts">
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Require 2 Factor Authentication" label-for="two_factor">
          <b-checkbox name="two_factor" v-model="editing.settings.two_factor_auth" inline plain></b-checkbox>
        </b-form-group>
    
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Require Security Question" label-for="require_security_question">
          <b-checkbox name="require_security_question" v-model="editing.settings.require_security_question" inline plain></b-checkbox>
        </b-form-group>
    
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Refresh token duration (seconds)" label-for="session_timeout" description="If a value is provided, it must be at least 11000. Anything under 11000 will be removed.">
          <b-form-input name="session_timeout" type="number" v-model.number="editing.settings.session_timeout" inline></b-form-input>
        </b-form-group>
    
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Idle timeout (seconds)" label-for="idle_timeout">
          <b-form-input name="idle_timeout" type="number" v-model.number="editing.settings.idle_timeout" inline></b-form-input>
        </b-form-group>
      </b-tab>

      <b-tab title="Advanced settings">
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="Use this organization for SAML login" label-for="isSamlLogin">
          <b-checkbox name="isSamlLogin" v-model="editing.settings.is_saml_login" inline plain></b-checkbox>
        </b-form-group>
    
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="SAML entry point" label-for="url">
          <b-input type="url" name="entryPoint" v-model="editing.settings.entryPoint"></b-input>
        </b-form-group>
    
        <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4" label="SAML certificate" label-for="cert">
          <b-textarea name="cert" v-model="editing.settings.cert"></b-textarea>
        </b-form-group>

        <b-button v-if="editing.id && editing.settings.is_saml_login" variant="white" size="sm" @click="getSamlMetadata">Generate SAML metadata</b-button>
        <b-button v-if="metadataLink" variant="link" :href="metadataLink" :download="metadataName">Download metadata</b-button>
      </b-tab>

      <b-tab title="Email rules">
        <div>
          <b-form-group  label-cols="12" label-cols-md="5" label-cols-lg="4" label="Email rules" label-for="rule">
            <b-input-group>
              <b-input type="text" name="rule" v-model="rule" class="mr-1"></b-input>
              <b-button :disabled="!rule.length" :style="{ background: brandColor, borderColor: 'transparent' }" @click="addRule(rule)">+ Rule</b-button>
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col md="5" lg="4">
              &nbsp;
            </b-col>
            <b-col>
              <ul class="pl-0 ml-0 mt-2 unstyled">
                <li v-for="(rule, index) in emailRules" :key="index" class="border-bottom pt-2 pb-3 ">
                  {{ rule }}<b-button @click="removeRule(index)" variant="white" size="sm" class="ml-3 float-right">Delete</b-button>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>

    <br>
    <b-row class="d-flex flex-sm-row mt-3">
      <b-col class="mt-3">
        <b-button block :disabled="!validForm" type="submit" :style="{ background: brandColor, borderColor: 'transparent' }"
          >Save</b-button>
      </b-col>
      <b-col class="mt-3">
        <b-button block @click="hideModal" type="button" variant="white"
          >Cancel</b-button
        >
      </b-col>
    </b-row>
    <b-row v-if="editing.id" class="mt-3">
      <b-col class="text-right">
        <b-button @click="destroy" size="sm" type="button" variant="outline-danger"> Delete this organization</b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
  import { deepCopy } from '../utils/helpers'
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'


  async function save(org) {
    try {
      const protocol = org.id ? HTTP.put : HTTP.post
      const put = org.id ? `/${org.id}` : ''
      const response = await protocol(`/api/organizations${put}`, org)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function destroy(id) {
    try {
      await HTTP.delete(`/api/organizations/${id}`)

      return {}
    } catch (error) {
      return { error }
    }
  }

  async function getSamlMetadata(id) {
    try {
      const response  = await HTTP.get(`/api/auth/saml/${id}/metadata`)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'OrgEdit',
    props: {
      org: {
        type: Object
      }
    },
    data() {
      return {
        editing: {},
        saving: false,
        rule: '',
        pwComplexityOptions: [
          {
            text: 'Any',
            value: 0
          },
          {
            text: 'Symbol or Number',
            value: 1
          },
          {
            text: 'Symbol and Number',
            value: 2
          }
        ],
        metadataLink: null
      }
    },
    created() {
      this.editing = deepCopy(this.org)

      if (!this.editing.settings.rules) {
        this.editing.settings.rules = []
      }
    },
    methods: {
      async save() {
        const response = await save(this.editing)

        if (response.error) {
          return
        }

        this.$emit('update', response)
      },
      async destroy() {
        if (this.editing.id) {
          await destroy(this.editing.id)
        }

        this.$emit('delete')
      },
      hideModal() {
        this.$emit('hide-modal')
      },
      addRule(rule) {
        this.editing.settings.rules.push(rule)
        this.rule = ''
      },
      removeRule(index) {
        const reactToChanges = { ...this.editing }

        reactToChanges.settings.rules.splice(index, 1)

        this.editing = { ...reactToChanges }
      },
      async getSamlMetadata() {
        this.clearMetadataObj()

        const response = await getSamlMetadata(this.editing.id)
        const data = new Blob([response.data],  { type: 'application/xml' })
        const file = new File([data], name)

        this.metadataLink = URL.createObjectURL(file, { oneTimeOnly: true })
      },
      clearMetadataObj() {
        if (this.metadataLink) {
          URL.revokeObjectURL(this.metadataLink)
          this.metadataLink = null
        }
      }
    },
    destroyed() {
      this.clearMetadataObj()
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      validForm() {
        return this.editing.name && this.editing.name.length && this.editing.settings
      },
      emailRules() {
        return this.editing.settings.rules
      },
      metadataName() {
        return `${this.editing.name}-saml-metadata.xml`
      }
    }
  }
</script>

<style scoped>
.unstyled {
  list-style: none;
}
</style>
