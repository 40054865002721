<template>
    <div>
        <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
            <b-navbar-brand href="#" style="color: #fff;">
                <img :src="brandImage" class="logo" />
                Change Security Question
            </b-navbar-brand>
        </b-navbar>
        <b-container style="max-width: 800px;">
            <b-card class="mt-3 p-4" no-body>
                <b-card-text>
                    <ChangeSecurityQuestion :login-token="loginToken" hide-cancel />
                </b-card-text>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import ChangeSecurityQuestion from './ChangeSecurityQuestion.vue'

import { SESSION } from '../utils/session'

import jwt_decode from 'jwt-decode'

export default {
    name: 'ChangeSecurityQuestionPage',
    components: {
        ChangeSecurityQuestion
    },
    data() {
        return {
            loginToken: ''
        }
    },
    created() {
        this.loginToken = this.$route.query.loginToken

        try {
            jwt_decode(this.loginToken)
        } catch (error) {
            this.$router.replace({ path: '/login' })
        }
    },
    computed: {
        brandColor() {
            return SESSION.branding.color
        },
        brandImage() {
            return require('../assets/' + SESSION.branding.image)
        }
    }
}
</script>

<style scoped>
</style>
