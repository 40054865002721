<template>
  <div>
    <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
      <b-navbar-brand href="#" style="color: #fff;">
        <img :src="brandImage" class="logo" />
        Confirm Email</b-navbar-brand>
    </b-navbar>
    <b-container style="max-width: 950px;">
      <b-card class="mt-3 p-4" no-body>
        <b-card-text>
          <b-form @submit.prevent="confirmEmailHandler" novalidate v-if="!loading" autocomplete="off">
            <b-row>
              <b-col sm="5">
                <label for="verification-code">Email verification code *</label>
              </b-col>
              <b-col sm="7">
                <b-form-input
                  autofocus
                  id="verification-code"
                  required
                  type="text"
                  v-model="code"
                  style="text-transform: uppercase"
                />
              </b-col>
            </b-row>
            <b-row class="d-flex flex-column flex-md-row mt-3">
              <b-col class="mt-2">
                <b-button block :disabled="!validForm" type="submit" :style="{ background: brandColor, borderColor: 'transparent' }"
                  >Confirm</b-button
                >
              </b-col>
              <b-col class="mt-2">
                <b-button block @click="cancel" type="button" variant="white"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </b-form>
            <div v-if="loading" class="text-center m-2">
              <b-spinner label="Loading"></b-spinner>
            </div>
          <div v-if="success">Email verifed. Redirecting...</div>
        </b-card-text>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { HTTP } from '../requests/requests'
  import { getLoginRedirect } from '../utils/helpers'
  import { SESSION } from '../utils/session'

  import jwt_decode from 'jwt-decode'

  async function confirmEmail(code, loginToken) {
    try {
      const response = await HTTP.post('/api/auth/confirm_email', {
        code,
        loginToken
      })

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ConfirmEmail',
    data() {
      return {
        code: '',
        loginToken: '',
        loading: false,
        success: false,
        appName: 'scorpion.caveon.com'
      }
    },
    async created() {
      this.code = this.$route.query.code
      this.loginToken = this.$route.query.loginToken

      try {
        jwt_decode(this.loginToken)
      } catch (error) {
        this.$router.replace({ path: '/login' })
      }

      if (
        this.code &&
        this.code.length &&
        this.loginToken &&
        this.loginToken.length
      ) {
        await this.confirmEmailHandler()
      }
    },
    methods: {
      async confirmEmailHandler() {
        this.loading = true
        const responseData = await confirmEmail(this.code, this.loginToken)
        this.loading = false

        if (responseData.error) {
          console.error(responseData.error)
          return
        }

        const { loginToken } = responseData

        const { error, data } = await API.sendLogin({ loginToken })
        if (error) {
          this.$bvToast.toast('Failed to login! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })
          return
        }

        const url = await getLoginRedirect(data)

        return this.$router.push({
          path: url
        })
      },
      cancel() {
        this.$router.push({ path: '/' })
      }
    },
    computed: {
      validForm() {
        return this.code && this.code.length === 6 && !this.loading
      },
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      }
    }
  }
</script>

<style scoped>
  .underline {
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid #efefef;
  }
</style>
