import Vue from 'vue'
import App from './App.vue'
import { router } from './router'

import VueMoment from 'vue-moment'

import VueSelect from 'vue-select'

import TextareaAutosize from 'vue-textarea-autosize'

import BootstrapVue from 'bootstrap-vue'
import './styles/app.scss'
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css'

import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { VueReCaptcha } from 'vue-recaptcha-v3'
import { RECAPTCHA_PUBLIC_KEY } from './utils/constants'

config.autoAddCss = false

library.add(fas)
library.add(far)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', VueSelect)

Vue.use(VueMoment)
Vue.use(BootstrapVue)
Vue.use(TextareaAutosize)
Vue.use(VueReCaptcha, {
  siteKey: RECAPTCHA_PUBLIC_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
})

Vue.directive('markdown', {
  bind: function(element, binding) {
    element.innerHTML = self.cavMarkdown.render(binding.value)
  },
  update: function(element, binding) {
    element.innerHTML = self.cavMarkdown.render(binding.value)
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
