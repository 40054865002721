<template>
  <div class="wrapper">
    <div class="message-box p-4">
      <b-row>
        <b-col md="3" class="text-center">
          <img src="../assets/logo-caveon-id.jpg" width="110" class="m-3" />
        </b-col>
        <b-col>
          <h3>
            Sorry, the page you're looking for does not exist or may have moved.
          </h3>
          <router-link to="/" custom v-slot="{ navigate }">
            <b-button @click="navigate" variant="primary-light">Return to Home</b-button>
          </router-link>
          <br /><br />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Error'
  }
</script>

<style lang="scss" scoped>
  @import '../styles/variables.scss';

  .message-box {
    background: $scorpion-primary;
    color: #fff;
    width: 85%;
    margin: 20px auto;
    border-radius: 8px;
  }
  .white-box {
    color: $scorpion-primary;
    background: #fff;
    border-radius: 8px;
  }
</style>
