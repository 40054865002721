<template>
  <b-modal id="two-factor-auth-modal" :title="modalTitle" @hidden="onModalHidden">
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <b-button variant="white" :disabled="loading" @click="switchTwoFactor">{{ changeAuthenticationMethodText }}</b-button>

        <div v-if="user.enable_multi_factor_auth">
          <b-button @click="cancel" variant="white" class="mr-2">Close</b-button>
          <b-button @click="disableTwoFactor" :disabled="loading" variant="danger">Disable</b-button>
        </div>

        <div v-else>
          <b-button @click="cancel" variant="white" class="mr-2">Cancel</b-button>
          <b-button @click="enableTwoFactor" :disabled="saveDisabled" :style="{ background: brandColor, borderColor: 'transparent' }">Save</b-button>
        </div>
      </div>
    </template>

    <div v-if="user.enable_multi_factor_auth">
      <div v-if="user.use_sms_for_two_factor">
        <p class="my-0">
          An SMS message with an access code will be sent to your phone when you log in.
        </p>
      </div>

      <div v-else>
        <p class="my-0">
          You will be asked to provide a key from the authentication app on your phone when you log in. 
        </p>

        <p v-if="user.phone_confirmed_at">
          You will also have the option to receive an SMS message with an access code sent to your phone.
        </p>

        <p v-else>
          If you verify a phone number, you will also have the option to receive an SMS message with an access code sent to your phone.
        </p>

        <hr />

        <h5>Did you replace your phone? Generate a new QR code:</h5>

        <p class="mb-0">
          Generating a new QR code will remove all previously generated codes and all saved trusted devices from your account.
        </p>

        <p>
          You will need to add the new QR code to your authentication app on your phone and re-enable two factor authentication.
        </p>

        <b-button
          @click="generateNewSecret()"
          :disabled="loading"
          class="mb-3"
          :style="{ background: brandColor, borderColor: 'transparent' }"
        >
          Generate New Code
        </b-button>
      </div>
    </div>

    <div v-else>
      <div v-if="user.use_sms_for_two_factor">
        <div v-if="user.phone_confirmed_at">
          You have a verified phone number and can use SMS for two factor authentication.
        </div>

        <div v-else>
          You will need to verify a phone number before you can use SMS for two factor authentication.
        </div>
      </div>

      <div v-else>
        <div class="instructions">
          <h5>Instructions</h5>
          <ol>
            <li>
              Install a two-factor authentication app on your phone (e.g. Google Authenticator or Authy).
            </li>
            <li>
              From the installed app, scan the QR code to add your Caveon Account.
            </li>
            <li>
              Enter the code from the authentication app on your phone.
            </li>
          </ol>
        </div>

        <p class="mt-4 mb-2">
          Scan this QR code with an authentication app on your phone:
        </p>

        <qrcode-vue
          v-if="otpauth"
          :value="otpauth"
          :size="256"
          level="H"
        />

        <p class="mt-4">
          Or you can manually enter this info into the authentication app on
          your phone:
          <br />
          <code>{{ user.email }}</code>
          <br />
          <code>{{ secret }}</code>
        </p>

        <b-form-group label="Code">
          <b-form-input autofocus placeholder="Enter Code From Authentication App" v-model="code" />
        </b-form-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { EVENT } from '../utils/eventBus'
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'

  import QrcodeVue from 'qrcode.vue'

  async function getNewSecret() {
    try {
      const url = '/api/auth/generate_new_secret'
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function submitTwoFactorStatus(status, code) {
    try {
      const url = '/api/auth/two_factor_status'
      const payload = { status }
      if (code) payload.code = code
      const response = await HTTP.post(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function submitUseSmsStatus(status) {
    try {
      const url = '/api/auth/use_sms_status'
      const payload = { status }
      const response = await HTTP.post(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'TwoFactorAuth',
    components: {
      QrcodeVue
    },
    props: {
      user: {
        type: Object
      }
    },
    data() {
      return {
        loading: false,
        code: ''
      }
    },
    methods: {
      async enableTwoFactor() {
        this.loading = true

        const { error } = await submitTwoFactorStatus(true, this.code)

        this.loading = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to enable two factor auth.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        const updatedUser = API.updateMe('enable_multi_factor_auth', true)

        EVENT.setGlobalUser(updatedUser)
      },
      async disableTwoFactor() {
        this.loading = true

        const { error } = await submitTwoFactorStatus(false)

        this.loading = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to disabled two factor auth.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        const updatedUser = API.updateMe('enable_multi_factor_auth', false)

        EVENT.setGlobalUser(updatedUser)

        this.$bvModal.hide('two-factor-auth-modal')
      },
      async generateNewSecret() {
        this.loading = true

        const { data, error } = await getNewSecret()

        this.loading = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to generate a new secret.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        API.updateMe('enable_multi_factor_auth', data.enable_multi_factor_auth)

        const updatedUser = API.updateMe('secret', data.secret)

        EVENT.setGlobalUser(updatedUser)
      },
      async switchTwoFactor() {
        const status = !this.user.use_sms_for_two_factor

        this.loading = true

        const { data, error } = await submitUseSmsStatus(status)

        this.loading = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to switch authentication method.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        API.updateMe('enable_multi_factor_auth', data.enable_multi_factor_auth)

        const updatedUser = API.updateMe('use_sms_for_two_factor', data.use_sms_for_two_factor)

        EVENT.setGlobalUser(updatedUser)

        this.code = ''
      },
      onModalHidden() {
        this.code = ''
      }
    },
    computed: {
      changeAuthenticationMethodText() {
        return this.user.use_sms_for_two_factor ? 'Use an authenticator app' : 'Use SMS'
      },
      saveDisabled() {
        return (this.user.use_sms_for_two_factor && !this.user.phone_confirmed_at) || this.loading
      },
      modalTitle() {
        if (this.user.enable_multi_factor_auth) {
          let method = 'SMS'

          if (!this.user.use_sms_for_two_factor) {
            method = this.user.phone_confirmed_at ? 'Authentication App (with SMS backup)' : 'Authentication App'
          }

          return `Two Factor Authentication via ${ method } is Enabled`
        }

        return 'Enable Two Factor Authentication'
      },
      brandColor() {
        return SESSION.branding.color
      },
      secret() {
        if (this.user.secret) {
          return this.user.secret.replace(/=/g, '')
        }

        return ''
      },
      otpauth() {
        const email = this.user.email
        const secret = this.secret
        return `otpauth://totp/${email}?secret=${secret}&issuer=Caveon`
      }
    }
  }
</script>

<style scoped>
  .instructions {
    background: #eee;
    padding: 15px;
    box-sizing: border-box;
  }
</style>
