<template>
  <div>
    <b-form @submit.prevent="changeSecurityQuestionHandler" novalidate v-if="!loading" autocomplete="off">
      <p v-if="loginToken">
        It appears you have not yet set a security question. Please do so now before continuing.
      </p>
      
      <b-row>
        <b-col sm="5">
          <label for="current-password">Current Password</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            placeholder="Required for Caveon ID authenticated accounts"
            autofocus
            id="current-password"
            type="password"
            v-model="currentPassword"
          />
        </b-col>
      </b-row>
      
      <b-row class="my-3">
        <b-col sm="5">
          <label for="security-question">Security Question</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="security-question"
            required
            type="text"
            placeholder="What is my favorite color?"
            v-model="securityQuestion"
          />
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col sm="5">
          <label for="security-answer">Answer</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="security-answer"
            required
            type="text"
            placeholder="Blue"
            v-model="securityAnswer"
          />
        </b-col>
      </b-row>

      <b-row class="d-flex flex-column flex-md-row mt-3">
        <b-col cols="6" class="mt-2">
          <b-button block :disabled="!validForm" type="submit" :style="{ background: brandColor, borderColor: 'transparent' }"
            >Submit</b-button
          >
        </b-col>
        <b-col cols="6" class="mt-2" v-if="!hideCancel">
          <b-button block @click="cancel" type="button" variant="white"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      
    </b-form>
    <div v-if="loading" class="text-center m-2">
      <b-spinner label="Loading"></b-spinner>
    </div>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { HTTP } from '../requests/requests'
  import { getLoginRedirect } from '../utils/helpers'
  import { SESSION } from '../utils/session'

  async function changeSecurityQuestionLoginToken(data, loginToken) {
    try {
      const payload = {
        loginToken,
        ...data
      }
      const response = await HTTP.post('/api/auth/change_security_question_login_token', payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function changeSecurityQuestion(data) {
    try {
      const payload = {
        ...data
      }
      const response = await HTTP.post('/api/auth/change_security_question', payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ChangeSecurityQuestion',
    props: {
      hideCancel: {
        type: Boolean,
        required: false
      },
      isModal: {
        type: Boolean,
        required: false
      },
      loginToken: {
        type: String,
        required: false
      },
      onSecurityQuestionChanged: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    data() {
      return {
        loading: false,
        currentPassword: '',
        securityQuestion: '',
        securityAnswer: ''
      }
    },
    methods: {
      async changeSecurityQuestionHandler() {
        this.loading = true
        const questionData = {
          currentPassword: this.currentPassword,
          securityQuestion: this.securityQuestion,
          securityAnswer: this.securityAnswer
        }

        let changeSecQ
        if (this.loginToken) {
          changeSecQ = changeSecurityQuestionLoginToken
        } else {
          changeSecQ = changeSecurityQuestion
        }

        const responseData = await changeSecQ(questionData, this.loginToken)

        this.clearForm()

        if (responseData.error) {
          console.error(responseData.error)
          this.$bvToast.toast(
            responseData.error.response.data,
            {
              title: 'Error',
              variant: 'danger',
              noAutoHide: false
            }
          )
          this.loading = false
          return
        }

        const { loginToken } = responseData

        if (loginToken) {
          const { error, data } = await API.sendLogin({ loginToken })
          if (error) {
            this.$bvToast.toast('Failed to login! Please try again.', {
              title: 'Failed',
              variant: 'danger',
              noAutoHide: true
            })
            this.loading = false
            return
          }

          const url = await getLoginRedirect(data)
          this.loading = false

          return this.$router.push({
            path: url
          })
        }

        this.onSecurityQuestionChanged(questionData.securityQuestion)

        this.loading = false
      },
      cancel() {
        if (this.isModal) {
          return this.$bvModal.hide('change-security-question-modal')
        }

        this.$router.push({ path: '/' })
      },
      clearForm() {
        this.securityQuestion = ''
        this.securityAnswer = ''
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      validForm() {
        if (!this.securityQuestion) return false
        if (!this.securityAnswer) return false

        return true
      }
    }
  }
</script>

<style scoped>
</style>
