<template>
<div>
  <div v-if="!loading">
    <b-row>
      <b-col>
        <h5>Caveon ID data</h5>
        <b-table :items="caveonIdData" outlined fixed small striped thead-class="d-none"></b-table>
        <b-table :items="caveonIdDatetimes" outlined fixed small striped thead-class="d-none"></b-table>
      </b-col>
    </b-row>

    <div v-for="(value, name) in siteResponses" :key="name">
      <b-row v-if="value && value.length">
        <b-col>
          <h5>{{ name }}</h5>
          <b-table :items="value" outlined fixed small striped thead-class="d-none"></b-table>
        </b-col>
      </b-row>
    </div>
    
    <b-row class="border-top pt-4">
      <b-col>
        <b-button v-b-modal.are-you-sure variant="outline-danger" size="sm" class="float-right">Delete my data</b-button>
        <p>You can remove all your personal data from Caveon services:</p>
      </b-col>
    </b-row>
    <b-modal id="are-you-sure" hide-footer hide-foot>
      <template #modal-title>
        <div class="d-flex align-items-center">
          <font-awesome-icon icon="exclamation-triangle" class="mr-2" style="color: #be1515;" />
          Confirm Delete
        </div>
      </template>

      <p>If you delete your data this account will no longer have access to any Scorpion projects or Core hubs. Your personal information will be removed from the logs, discussions, roles and comments within all projects and hubs.</p>

      <b-row>
        <b-col sm="5">
          <label for="password">Enter your password to delete your data:</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            autofocus
            id="password"
            required
            type="password"
            v-model="password"
          />
        </b-col>
      </b-row>

      <b-row class="d-flex flex-column flex-md-row mt-3">
        <b-col cols="6" class="mt-2">
          <b-button block :disabled="validForm" variant="danger" @click="deleteMyData"
            >Delete My Data</b-button
          >
        </b-col>
        <b-col cols="6" class="mt-2">
          <b-button block @click="keepIt" variant="white"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
  <div v-else class="text-center m-2">
    <b-spinner />
  </div>
</div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { HTTP } from '../requests/requests'


  async function deleteMyData(payload) {
    try {
      await HTTP.put(`/api/my_data`, payload)

      return { data: true }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'MyData',
    data() {
      return {
        loading: true,
        caveonIdData: [],
        caveonIdDatetimes: [],
        siteResponses: [],
        password: ''
      }
    },
    async created() {
      const myData = await API.myData()

      this.caveonIdData = myData.caveonIdData
      this.caveonIdDatetimes = myData.caveonIdDatetimes.map(keyValue => {
        return [ keyValue[0], this.$moment.utc(keyValue[1]).local().format('L LT') ]
      })
      this.siteResponses = myData.siteResponses

      this.loading = false
    },
    methods: {
      async deleteMyData() {
        const { error } = await deleteMyData({ password: this.password })

        if (error) {
          this.$bvToast.toast(
            'Failed to delete your data! Please try again.',
            {
              title: 'Failed',
              variant: 'danger',
              noAutoHide: true
            }
          )
          return
        }

        window.localStorage.clear()

        this.$bvToast.toast(
          'Data delete success',
          {
            title: 'Success',
            variant: 'success',
            noAutoHide: true
          }
        )

        window.location.replace('/login')
      },
      keepIt() {
        this.$bvModal.hide('are-you-sure')
        this.password = ''
      }
    },
    computed: {
      validForm() {
        return !this.password?.length
      }
    }
  }
</script>

<style scoped>
  .underline {
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid #efefef;
  }
  h5 {
    width: 100%;
    padding: 10px 0;
  }
</style>
