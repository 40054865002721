<template>
    <b-row>
      <b-col v-for="(app, index) in apps" :key="index" class="align-center">
        <a :href="app.link" target="blank">
          <img :src="app.logo" :alt="app.name" class="app-logo" />
          <br>
          {{app.link}}
        </a>
      </b-col>
    </b-row>
</template>

<script>
class AppLink {
  constructor(name, logo, link) {
    this._name = name
    this._logo = logo
    this._link = link
  }

  get name() {
    return this._name
  }

  get logo() {
    return this._logo
  }

  get link() {
    return this._link
  }
}

export default {
  name: 'AppLinks',
  props: {
    isPreview: {
      type: Boolean,
      defaultValue: false
    }
  },
  data() {
    const preview = this.isPreview ? 'preview' : ''

    return {
      apps: [
        new AppLink('Scorpion', require('../assets/button-scorpion.jpg'), `https://scorpion${preview}.caveon.com`),
        new AppLink('Core', require('../assets/button-core.jpg'), `https://core${preview}.caveon.com`),
        new AppLink('SmartItems', require('../assets/button-smartitemapi.jpg'), `https://smart${preview}.caveon.com`)
      ]
    }
  }
}
</script>

<style scoped>
  .app-logo {
    width: 100px;
    margin: 20px;
    height: 100px;
  }
  .align-center {
    text-align: center;
  }
</style>
