<template>
  <div class="d-relative">
    <b-overlay :show="idleOverlayShown" no-wrap no-fade fixed z-index="1050" opacity="1">
      <template #overlay>
        <div class="text-center">
          <div class="d-block text-center">
            <h3>Are you still working?</h3>
            <h3>Select <span class="btn-ref">Yes</span> to continue working or <span class="btn-ref">No</span> to logout.</h3>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <b-button :style="{ background: brandColor, borderColor: 'transparent' }" @click="demandStayActive">Yes, continue working.</b-button>
            <b-button variant="white" class="ml-2" @click="logoutHandler">No, logout.</b-button>
          </div>
        </div>
      </template>
    </b-overlay>

    <b-overlay :show="authOverlayShown" no-wrap no-fade fixed z-index="1050" opacity="1">
      <template #overlay>
        <div class="text-center">
          <div class="d-block text-center">
            <h3>Your authentication has reached its expiration.</h3>
            <h3>To continue working, please login again.</h3>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <b-button :style="{ background: brandColor, borderColor: 'transparent' }" @click="logoutHandler">Login</b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template> 

<script>
  import jwt_decode from 'jwt-decode'
  import throttle from 'lodash.throttle'

  import { storage } from  '../utils/storage'
  import { logoutUser, postMessage } from '../utils/helpers'
  import { SESSION } from '../utils/session'

  export default {
    name: 'IdleDetection',
    props: {
      user: {
        type: Object
      }
    },
    async created() {
      this.idleTimeout = this.user.org.settings.idle_timeout
      this.createSession()
    },
    beforeDestroy() {
      this.unregisterListeners()
    },
    destroyed() {
      clearInterval(this.idleInterval)
    },
    data() {
      return {
        usedSeconds: 0,
        idleInterval: null,
        idleTimeout: null,
        authOverlayShown: false,
        idleOverlayShown: false,
        isFirstLoop: true,
        oldTitle: ''
      }
    },
    methods: {
      createSession() {
        this.tick()
        this.idleInterval = setInterval(this.tick, 1000)
      },
      registerListeners() {
        this.throttled = throttle(this.updateLastActivity, 5000)

        window.onmousemove = this.throttled
        window.onmousedown = this.throttled
        window.ontouchstart = this.throttled
        window.onclick = this.throttled
        window.onkeydown = this.throttled
        window.addEventListener('scroll', this.throttled, true)
      },
      unregisterListeners() {
        window.onmousemove = null
        window.onmousedown = null
        window.ontouchstart = null
        window.onclick = null
        window.onkeydown = null
        window.removeEventListener('scroll', this.throttled, true)
      },
      updateLastActivity() {
        storage.lastActivity = Date.now() / 1000
      },
      tick() {
        const refreshToken = storage.refreshToken
        const lastActivity = storage.lastActivity

        const now = Date.now() / 1000

        if (this.isFirstLoop) {
          this.registerListeners()
        }

        if (this.idleTimeout) {
          const difference = now - lastActivity
          const buffer = 30

          if (difference > this.idleTimeout) {
            return this.logoutHandler()
          }

          if (difference > this.idleTimeout - buffer && !this.idleOverlayShown) {
            this.offerStayActive()
          }
        }

        if (refreshToken) {
          const decoded = jwt_decode(refreshToken)
          const buffer = 300
          const difference = decoded.exp - now

          const forceLogoutDifference = this.isFirstLoop ? 3600 * 3 : 0

          if (difference < 0 + forceLogoutDifference) {
            return this.logoutHandler()
          }

          if (difference - buffer < 0 && !this.authOverlayShown) {
            this.offerReauthenticate()
          }
        }

        this.usedSeconds++

        this.isFirstLoop = false
      },
      offerReauthenticate() {
        this.authOverlayShown = true

        postMessage({
          type: 'caveonid_open_modal'
        })
      },
      offerStayActive() {
        this.oldTitle = document.title
        document.title = 'Are you still working?'

        this.idleOverlayShown = true

        postMessage({
          type: 'caveonid_open_modal'
        })
      },
      demandStayActive() {
        document.title = this.oldTitle

        postMessage({
          type: 'caveonid_close_modal'
        })

        this.usedSeconds = 0

        this.idleOverlayShown = false

        storage.lastActivity = Date.now() / 1000
      },
      logoutHandler() {
        document.title = this.oldTitle
        logoutUser()
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn-ref {
    font-weight: bold;
  }
  .session-info {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30px;
    background-color: black;
    color: #fff;
    opacity: .5;
    padding-left: 5px;
    padding-right: 5px;
  }
</style>
