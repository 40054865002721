<template>
  <div>
    <div v-show="!loading">
      <b-row class="mt-1">
        <b-col>
          <label for="user-email-search">Filter Users</label>
            <b-input
              v-model="email"
              id="user-email-search"
              placeholder="Enter email"
              @keypress.enter="searchUsers()"
            ></b-input>
            <b-row class="mt-4">
              <b-col>
                <b-form-checkbox
                  v-model="activeOnly"
                  id="checkbox-1"
                  name="checkbox-1"
                  plain
                >
                Only show active users
                </b-form-checkbox>
              </b-col>
              <b-col cols="6" md="" class="text-right">
                <b-button class="mr-2" :disabled="loading" :style="{ background: brandColor, borderColor: 'transparent' }" @click="searchUsers()"
                  >Search</b-button
                >
                <b-button variant="white" @click="clearSearch()">Clear</b-button>
              </b-col>
            </b-row>
        </b-col>
      </b-row>
      <b-table
        class="mt-4"
        id="users-table"
        :items="usersForTable"
        :fields="fields"
        :per-page="perPage"
        :current-page="page"
        hover
        bordered
        responsive
        large
        striped
      >
        <template #cell(userInfo)="data">
          <div>{{ data.item.first_name }} {{ data.item.last_name }}</div>
          <div>{{ data.item.email }}</div>
        </template>
        <template #cell(apps)="data">
          <div>
            <span v-for="(app, index) in data.item._apps" :key="index" :class="{ 'app-active': app.active }">
              {{ app.text }}<span v-if="index !== data.item._apps.length - 1">,&nbsp;</span>
            </span>
          </div>
          <div>Current login: {{ formatDate(data.item.current_login_at) }}</div>
        </template>
        <template #cell(actions)="row">
          <b-dropdown text="Actions" class="float-right" variant="white" size="sm" boundary="window">
            <b-dropdown-item @click="verifyUser(row.item)">Verify user</b-dropdown-item>
            <b-dropdown-item><ProxyUser :user="row.item" :asText="true" /></b-dropdown-item>
            <b-dropdown-item @click="reset(row, 'pw')">Reset password</b-dropdown-item>
            <b-dropdown-item @click="reset(row, '2fa')">Disable two factor auth</b-dropdown-item>
            <b-dropdown-item @click="revoke(row)">Revoke refresh token</b-dropdown-item>
            <b-dropdown-item @click="showUserInfo(row.item)">View user profile</b-dropdown-item>
            <b-dropdown-item @click="showUserLogs(row.item)">View user logs</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-pagination
        v-model="page"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="users-table"
      ></b-pagination>

    </div>

    <div v-if="loading" class="text-center m-2">
      <b-spinner label="Loading"></b-spinner>
    </div>

    <!-- User Logs Modal -->
    <b-modal id="user-logs-modal" title="User Logs">
      <Logs :email="user.email" v-if="user"/>
      <template #modal-footer="{ cancel }">
        <b-row class="d-flex flex-column flex-md-row w-100">
          <b-col cols="6" />
          <b-col cols="6">
            <b-button block @click="cancel" type="button" variant="white"
              >Close</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <!-- User Info Modal -->
    <b-modal id="user-info-modal" title="User Profile">
      <div v-if="user">
        <b-row class="my-3">
          <b-col md="4" lg="3">
            <label for="input-small">Email address</label>
          </b-col>
          <b-col>
            <b>{{ user.email }}</b>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col md="4" lg="3">
            <label for="input-small">First name</label>
          </b-col>
          <b-col class="strong">
            <b>{{ user.first_name }}</b>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col md="4" lg="3">
            <label for="input-small">Last name</label>
          </b-col>
          <b-col>
            <b>{{ user.last_name }}</b>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col md="4" lg="3">
            <label for="input-small">Phone</label>
          </b-col>
          <b-col>
            <b>{{ user.phone }}</b>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col md="4" lg="3">
            <label for="input-small">Current login</label>
          </b-col>
          <b-col>
            <b>{{ formatDate(user.current_login_at) }}</b>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col md="4" lg="3">
            <label for="input-small">Last login</label>
          </b-col>
          <b-col>
            <b>{{ formatDate(user.last_login_at) }}</b>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col md="4" lg="3">
            <label for="input-small">Last changed password</label>
          </b-col>
          <b-col>
            <b>{{ formatDate(user.password_changed_at) }}</b>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ cancel }">
        <b-row class="d-flex flex-column flex-md-row w-100">
          <b-col cols="6" />
          <b-col cols="6">
            <b-button block @click="cancel" type="button" variant="white"
              >Close</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>

  </div>
</template>

<script>
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'

  import ProxyUser from './ProxyUser'
  import Logs from './Logs'


  async function revoke(caveonId) {
    try {
      const response = await HTTP.post('/api/users/revoke', {
        caveon_id: caveonId
      })

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function reset(caveonId, type) {
    try {
      const response = await HTTP.post('/api/users/reset', {
        caveon_id: caveonId,
        type
      })

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function sendVerifyEmail(caveonId) {
    try {
      const response = await HTTP.post('/api/verify/send_email', {
        caveon_id: caveonId
      })

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function getUsers(filters) {
    try {
      const { page, perPage, search, activeOnly } = filters
      const safeSearch = encodeURIComponent(search)
      const response = await HTTP.get(`/api/users?page=${page}&per_page=${perPage}&search=${safeSearch}&active_only=${activeOnly}`)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Users',
    components: {
      ProxyUser,
      Logs
    },
    data() {
      return {
        loading: false,
        user: {},
        users: [],
        perPage: 100,
        page: 1,
        total: 0,
        fields: [
          { key: 'userInfo', label: 'User' },
          { key: 'apps', label: 'Apps (active is bold)' },
          { key: 'actions', label: '', headerTitle: 'Actions' }
        ],
        email: '',
        activeOnly: true
      }
    },
    async created() {
      this.users = await this.getUsers()
    },
    methods: {
      async revoke(row) {
        const { error } = await revoke(row.item.caveon_id)

        if (!error) {
          this.$bvToast.toast(
            'Revoked users refresh token.',
            {
              title: 'Success',
              variant: 'success',
              noAutoHide: true
            }
          )

          if (this.activeOnly) {
            this.users.splice(row.index, 1)
          }
        }
      },
      async getUsers() {
        this.loading = true

        const filters = {
          page: this.page,
          perPage: this.perPage,
          search: this.email,
          activeOnly: this.activeOnly
        }
        const response = await getUsers(filters)

        this.total = response.total

        this.loading = false
        return response.results
      },
      async reset(row, type) {
        const response = await reset(row.item.caveon_id, type)
        const message = response.password ? 'Password set to: ' + response.password : 'Two factor auth disabled'

        this.$bvToast.toast(
          message,
          {
            title: 'Success',
            variant: 'success',
            noAutoHide: true
          }
        )
      },
      async verifyUser(user) {
        const response = await sendVerifyEmail(user.caveon_id)
        if (response.error) {
          this.$bvToast.toast('Failed to send email!', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })
          return
        }

        this.$bvToast.toast(
          'Verification email successfully sent! See user logs for details.',
          {
            title: 'Success',
            variant: 'success',
            noAutoHide: true
          }
        )
      },
      async searchUsers() {
        this.users = await this.getUsers()
      },
      async clearSearch() {
        this.page = 1
        this.perPage = 100
        this.search = ''
        this.activeOnly = true

        this.users = await this.getUsers()
      },
      showUserInfo(userInfo) {
        this.user = userInfo
        this.$bvModal.show('user-info-modal')
      },
      showUserLogs(userInfo) {
        this.user = userInfo
        this.$bvModal.show('user-logs-modal')
      },
      formatDate(date) {
        return this.$moment
                .utc(date)
                .local()
                .format('L LT')
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      usersForTable() {
        const users = [ ...this.users ]
        const oneMonthAgo = (Date.now() / 1000) - (86400 * 30)

        for (const user of users) {
          user._apps = []
          for (const [ name, lastUsedAt ] of Object.entries(user.apps)) {
            user._apps.push({ text: name, active: lastUsedAt > oneMonthAgo })
          }
        }

        return users
      }
    }
  }
</script>

<style scoped>
  .app-active {
    font-weight: bold;
  }
</style>
