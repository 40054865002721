<template>
  <div class="wrapper">
    <div class="attention">!</div>
    <h4>Page Not Found</h4>
    <p>The page you are requesting could not be found.</p>
    <router-link to="/" custom v-slot="{ navigate }">
      <b-button @click="navigate" variant="primary-light">Return to Home Page</b-button>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'PageNotFound'
  }
</script>

<style lang="scss" scoped>
  @import '../styles/variables.scss';

  .wrapper {
    height: 100%;
    padding: 6.25rem 2rem;
    text-align: center;

    .attention {
      background: $scorpion-primary-light;
      color: #fff;
      padding: 0.5rem 1.8rem;
      border-radius: 100%;
      display: inline-block;
      font-size: 3.25rem;
      margin-bottom: 1.25rem;
      line-height: 1;
    }
  }
</style>
