<template>
  <div>
    <div id="app">
      <div align-self="stretch">
        <router-view
          v-if="ready"
          :user="user" 
          @user-updated="onUserUpdated" 
        />
        <div v-else>
          <div class="text-center m-2">
            <b-spinner label="Loading"></b-spinner>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center border-top terms-and-conditions-footer">
      <small>
        &copy; {{ currentYear }} Caveon, LLC. All rights reserved. <a href="/terms_and_policies" title="Terms" target="_blank">Terms & Policies</a>
      </small>
    </div>
    <IdleDetection 
      v-if="user.caveon_id" 
      :user="user" 
    />
  </div>
</template>

<script>
  import { EVENT } from './utils/eventBus'
  import { setAuthHeader, HTTP } from './requests/requests'
  import { SESSION } from './utils/session'
  import { storage, ACCESS_TOKEN_KEY } from  './utils/storage'
  import { logoutUser, postMessage } from './utils/helpers'
  import { REFRESH_ACCESS_TOKEN_INTERVAL } from './utils/constants'

  import IdleDetection from './components/IdleDetection.vue'

  async function refresh(refreshToken) {
    const url = '/api/auth/refresh'

    const payload = {
      refreshToken
    }

    try {
      return await HTTP.post(url, payload)
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'App',
    components: {
      IdleDetection
    },
    data: () => {
      return {
        user: {},
        ready: false
      }
    },
    watch: {
      $route(to) {
        if (to.name === 'Login') {
          const accessToken = storage.accessToken

          if (!accessToken) {
            this.user = {}
          }
        }
      }
    },
    async created() {
      EVENT.$on(EVENT.globalAlertEvent, this.onGlobalAlertEvent)

      EVENT.$on(EVENT.setGlobalUserEvent, this.onSetGlobalUserEvent)

      window.onstorage = this.onStorage
      window.onmessage = this.onMessage

      this.startRefreshInterval()

      const client = this.$route.query.client

      if (client) {
        SESSION.setClientAndBranding(client)
      }

      await this.attemptRefresh()

      if (!storage.accessToken) {
        postMessage({
          type: 'caveonid_access_token'
        })

        const pushToLogin = !['/login', '/register', '/confirm_email', '/reset_password', '/terms_and_policies'].includes(this.$route.path)

        if (pushToLogin) {
          this.$router.push({ path: '/login' })
        }
      }

      this.ready = true
    },
    methods: {
      onStorage(event) {
        switch (event.key) {
          case ACCESS_TOKEN_KEY:
            postMessage({
              type: 'caveonid_access_token',
              accessToken: storage.accessToken
            })
            break
        }
      },
      onMessage(event) {
        switch (event.data.type) {
          case 'caveonid_activity':
            storage.lastActivity = Date.now() / 1000
            break
          case 'refresh_access_token':
            this.attemptRefresh()
            break
        }
      },
      onUserUpdated(updatedUser) {
        this.user = updatedUser
      },
      onGlobalAlertEvent(args) {
        let { message, title, variant } = args

        if (!title) {
          title = variant === 'success' ? 'Success' : 'Error'
        }

        const options = {
          autoHideDelay: 3000,
          solid: true,
          title,
          variant
        }

        this.$bvToast.toast(message, options)
      },
      onSetGlobalUserEvent(updatedUser) {
        this.user = updatedUser
      },
      startRefreshInterval() {
        setInterval(async () => {
          await this.attemptRefresh()
        }, REFRESH_ACCESS_TOKEN_INTERVAL)
      },
      async attemptRefresh() {
        const refreshToken = storage.refreshToken
        const response = await refresh(refreshToken)
        let headers = {}

        if (response.error) {
          headers = response.error.response.headers
          logoutUser(false)
        } else {
          headers = response.headers
        }

        this.setSitesFromHeaders(headers)

        if (response.data?.accessToken) {
          storage.accessToken = response.data.accessToken
          setAuthHeader(response.data.accessToken)
        }
      },
      setSitesFromHeaders(headers) {
        try {
          SESSION.sites = JSON.parse(headers['x-caveonid-sites'])
        } catch (e) {
          SESSION.sites = []
        }
      }
    },
    computed: {
      currentYear () {
        return new Date().getFullYear()
      }
    }
  }
</script>

<style lang="scss">
  #app {
    background-color: #fff;
    padding-bottom: 40px;
    min-height: calc(100vh - 50px);
  }

  .terms-and-conditions-footer {
    height: 50px;
  }
</style>
