<template>
  <div>
    <p>
      Your password does not meet the minimum requirements:
    </p>
    <div v-for="(requirement, index) in reqs" :key="index">
      <i>
        <div v-if="requirement[0] == 'pw_length'">
          <p>Minimum of {{ requirement[1] }} characters.</p>
        </div>

        <div v-if="requirement[0] == 'pw_complexity'">
          <p>Must contain a {{ complexityText(requirement[1]) }}.</p>
        </div>

        <div v-if="requirement[0] == 'pw_life'">
          <p>Password expires every {{ requirement[1] }} days.</p>
        </div>

        <div v-if="requirement[0] == 'pw_hash'">
          <p>Password must be changed.</p>
        </div>

        <div v-if="requirement[0] == 'pw_reset'">
          <p>Password was reset and must be changed.</p>
        </div>

        <div v-if="requirement[0] == 'pw_already_used'">
          <p>Password cannot be the same as a recent password.</p>
        </div>
      </i>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UnfulfilledPwdReqs',
    props: {
      reqs: {
        type: Array,
        default: () => []
      },
    },
    methods: {
      complexityText(value) {
        if (value === 1) {
          return 'symbol or number'
        }

        if (value === 2) {
          return 'symbol and number'
        }
      },
    },
  }
</script>

<style scoped>
</style>
