<template>
  <div>
    <!-- <h5>{{ title }}</h5> -->
    <b-table
      id="agreements-table"
      :fields="fields"
      :items="agreements"
      hover
      responsive
      small
      bordered
      striped
      v-if="agreements.length"
    >
      <template v-slot:cell(active)="row">
        <b-checkbox
          name="active-checkbox"
          @change="activeAgreementChanged(row.item, $event)"
          :disabled="disabled"
          v-model="row.item.active"
          plain
        ></b-checkbox>
      </template>
      <template v-slot:cell(content)="row">
        <div v-if="contentIsShort(row.item.content)">
          {{ row.item.content }}
        </div>
        <div v-else>
          <b-button
            type="button"
            variant="white"
            size="sm"
            @click="displayContentModal(row.item.content)"
            >View Content</b-button
          >
        </div>
      </template>
    </b-table>
    <b-modal id="view-content-modal" title="Content" scrollable ok-only>
      <p v-markdown="modalContent" />
      <template #modal-footer="{ cancel }">
        <b-row class="d-flex flex-column flex-md-row w-100">
          <b-col cols="6" />
          <b-col cols="6">
            <b-button block @click="cancel" type="button" variant="white"
              >Close</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'AgreementListing',
    props: {
      agreements: {
        type: Array,
        required: true
      },
      organizations: {
        type: Array,
        required: false,
        default: () => {
          return []
        }
      },
      fields: {
        type: Array,
        required: true
      },
      updateAgreement: {
        type: Function,
        default: () => {}
      },
      title: {
        type: String,
        default: () => {
          return ''
        }
      },
      disabled: {
        type: Boolean,
        default: () => {
          return false
        }
      }
    },
    data() {
      return {
        modalContent: ''
      }
    },
    created() {
      this.setOrgNames()
    },
    methods: {
      setOrgNames() {
        for (const [index, agreement] of this.agreements.entries()) {
          const orgId = agreement.organization_id
          if (orgId) {
            this.agreements[index].organization = this.getOrgName(orgId)
          }
        }
      },
      getOrgName(id) {
        for (const org of this.organizations) {
          if (org.id === id) {
            return org.name
          }
        }

        return ''
      },
      activeAgreementChanged(agreement, isActive) {
        agreement.active = isActive
        this.updateAgreement(agreement)
      },
      contentIsShort(content) {
        if (content && content.length > 100) {
          return false
        }
        return true
      },
      displayContentModal(content) {
        this.modalContent = content
        this.$bvModal.show('view-content-modal')
      }
    }
  }
</script>

<style></style>
