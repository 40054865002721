<template>
  <div v-if="loading" class="text-center m-2">
    <b-spinner label="Loading"></b-spinner>
  </div>
  <div v-else>
    <br>
    <b-card
      class="m-3 scroll-box"
      :title="currentAgreement.name"
      :sub-title="currentAgreement.modified_at"
    >
      <b-card-text v-markdown="currentAgreement.content"></b-card-text>
    </b-card>
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <b-button class="mt-3 mr-3" @click="goToNextAgreement(true)"
            >Accept</b-button
          >
          <b-button class="mt-3 mr-3" @click="goToNextAgreement(false)"
            >Decline</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { HTTP } from '../requests/requests'
  import { getLoginRedirect } from '../utils/helpers'

  import jwt_decode from 'jwt-decode'

  async function submitAcceptAgreements(agreementIds, loginToken) {
    try {
      const response = await HTTP.post('/api/auth/accept_agreements', {
        agreementIds,
        loginToken
      })

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function getNeedToAccept(loginToken) {
    try {
      const response = await HTTP.post('/api/auth/agreements_to_accept', {
        loginToken
      })

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'AgreementAccept',
    data() {
      return {
        loading: true,
        agreements: [],
        loginToken: '',
        step: 0
      }
    },
    async created() {
      this.loginToken = this.$route.query.loginToken

      try {
        jwt_decode(this.loginToken)
      } catch (error) {
        return this.$router.replace({ path: '/login' })
      }

      const { error, data } = await getNeedToAccept(this.loginToken)
      if (error) {
        this.loading = false
        return
      }

      const { agreements } = data
      this.agreements = agreements

      if (this.agreements.length < 1) {
        return this.$router.replace({ path: '/login' })
      }

      this.loading = false
    },
    methods: {
      goToNextAgreement(isContinue) {
        if (!isContinue) {
          return this.$router.push({ path: '/' })
        }
        const nextStep = this.step + 1
        if (nextStep > this.agreements.length - 1) {
          this.acceptAgreements()
          return
        }
        this.step = nextStep
      },
      async acceptAgreements() {
        const { error, data } = await submitAcceptAgreements(
          this.agreementIds,
          this.loginToken
        )
        if (error) {
          this.$bvToast.toast(
            'Failed to accept agreements! Please try again.',
            {
              title: 'Failed',
              variant: 'danger',
              noAutoHide: true
            }
          )
          return
        }

        const { loginToken } = data

        const result = await API.sendLogin({ loginToken })
        if (result.error) {
          this.$bvToast.toast('Failed to login! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })
          return
        }

        const url = await getLoginRedirect(result.data)

        return this.$router.push({
          path: url
        })
      }
    },
    computed: {
      currentAgreement() {
        const agreement = this.agreements[this.step]
        agreement.modified_at = this.$moment
          .utc(agreement.modified_at)
          .local()
          .format('L LT')

        return agreement
      },
      agreementIds() {
        const ids = []
        for (const agreement of this.agreements) {
          ids.push(agreement.id)
        }

        return ids
      }
    }
  }
</script>

<style></style>
