<template>

  <div v-if="!loaded" class="text-center m-2">
    <b-spinner label="Loading"></b-spinner>
  </div>
  <div v-else>    
    <b-form @submit.prevent="createAgreement()">
      <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4"  label="Name *" label-for="name-input">
        <b-form-input
          id="name-input"
          autofocus
          type="text"
          v-model="createForm.name"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4"  label="Organization" label-for="organization-input">
        <b-form-select
          :options="orgOptions"
          id="organization-input"
          v-model="createForm.organization_id"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4"  label="Active" label-for="active-input">
        <b-form-checkbox
          v-model="createForm.active"
          name="active-input"
          size="lg"
          switch
        >
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label-cols="12" label-cols-md="5" label-cols-lg="4"  label="Content" label-for="content-input">
        <textarea-autosize
          id="content-input"
          :min-height="37"
          class="form-control"
          v-model="createForm.content"
          required
        ></textarea-autosize>
      </b-form-group>

      <b-row class="mt-3">
        <b-col class="mt-3">
          <b-button type="submit"
          block
          :disabled="creating"
          :style="{ background: brandColor, borderColor: 'transparent' }"
            >Save</b-button>
        </b-col>
        <b-col class="mt-3">
          <b-button block @click="cancel()" type="button" variant="white"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'

  async function submitNewAgreement(agreement) {
    try {
      const response = await HTTP.post(`/api/agreements`, {
        ...agreement
      })

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'AgreementCreate',
    props: {
      addAgreement: {
        type: Function,
        required: true
      },
      organizations: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        loaded: false,
        creating: false,
        orgOptions: [{ value: '', text: 'Global Org' }],
        createForm: {
          name: '',
          organization_id: '',
          active: false,
          content: ''
        }
      }
    },
    async created() {
      for (const org of this.organizations) {
        this.orgOptions.push({
          value: org.id,
          text: org.name
        })
      }
      this.loaded = true
    },
    methods: {
      async createAgreement() {
        this.creating = true
        const { data, error } = await submitNewAgreement(this.createForm)
        if (error) {
          this.$bvToast.toast(
            'Failed to create new agreement! Please try again.',
            {
              title: 'Failed',
              variant: 'danger',
              noAutoHide: true
            }
          )
          return
        }
        this.addAgreement(data)
        this.creating = false
      },
      cancel() {
        this.$bvModal.hide('create-agreement-modal')
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      }
    }
  }
</script>

<style></style>
