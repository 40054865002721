<template>
  <div>
    <div v-if="!loading">
      <b-navbar type="dark" class="mb-4 mr-0 pr-0" :style="{ background: brandColor }">
        <b-row class="w-100 mr-0 pr-0">
          <b-col cols="12"><img :src="brandImage" class="logo float-left mt-0 pt-0" /> <b-navbar-brand href="#" style="color: #fff;">Administrator Tools</b-navbar-brand></b-col>
          <b-col cols="12" class="pr-0">
            <b-button-group class="d-flex">
              <router-link to="/" custom v-slot="{ navigate }">
                <b-button @click="navigate" variant="white" size="sm">User Dashboard</b-button>
              </router-link>
              <b-button @click="logoutUser" variant="white" size="sm">Log Out</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-navbar>
      <b-container style="max-width: 950px;">
        <b-card class="mt-3" no-body>
          <b-tabs card>
              <b-tab title="Users">
                <b-card-text>
                  <Users />
                </b-card-text>
              </b-tab>
              <b-tab title="Agreements">
                <b-card-text>
                  <Agreements />
                </b-card-text>
              </b-tab>
              <b-tab title="Organizations">
                <b-card-text>
                  <Orgs />
                </b-card-text>
              </b-tab>
          </b-tabs>
        </b-card>
      </b-container>
    </div>
    <div v-else class="d-flex justify-content-center py-4">
      <b-spinner />
    </div>
  </div>
</template>

<script>
  import { SESSION } from '../utils/session'
  import { logoutUser } from '../utils/helpers'
  import { storage } from '../utils/storage'
  import { EVENT } from '../utils/eventBus'
  import { API } from '../requests/api-cache'

  import Agreements from './Agreements'
  import Orgs from './Orgs'
  import Users from './Users'


  export default {
    name: 'AdminPage',
    components: {
      Agreements,
      Orgs,
      Users
    },
    props: {
      user: {
        type: Object
      }
    },
    data() {
      return {
        loading: true,
        logoutUser
      }
    },
    async created() {
      if (!storage.accessToken) {
        return this.$router.replace({ path: '/login' })
      }

      let roles = this.user.roles

      if (!this.user.caveon_id) {
        const user = await API.getMe(true)

        EVENT.setGlobalUser(user)

        roles = user.roles
      }

      if (!roles.includes('admin')) {
        return this.$router.replace({ path: '/login' })
      }

      this.loading = false
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      }
    }
  }
</script>

<style scoped></style>
