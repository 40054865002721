import Vue from 'vue'
import Router from 'vue-router'

import Error from '../components/Error.vue'
import PageNotFound from '../components/PageNotFound.vue'

import Dashboard from '../components/Dashboard.vue'
import Register from '../components/Register.vue'
import Login from '../components/Login.vue'
import ConfirmEmail from '../components/ConfirmEmail.vue'
import ConfirmTwoFactor from '../components/ConfirmTwoFactor.vue'
import AgreementAccept from '../components/AgreementAccept.vue'
import PasswordRequirements from '../components/PasswordRequirements.vue'
import TwoFactorRequirement from '../components/TwoFactorRequirement.vue'
import ChangeSecurityQuestionPage from '../components/ChangeSecurityQuestionPage.vue'
import ResetPasswordPage from '../components/ResetPasswordPage.vue'
import AdminPage from '../components/AdminPage.vue'
import VerifyIdentity from '../components/VerifyIdentity.vue'
import TermsAndPolicies from '../components/TermsAndPolicies.vue'

Vue.use(Router)

function createRouter() {
  return new Router({
    mode: 'history',
    scrollBehavior: () => ({
      y: 0
    }),
    routes: [
      {
        path: '/',
        component: Dashboard,
        name: 'Dashboard',
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: '/error',
        component: Error,
        name: 'error',
        meta: {
          title: 'Error'
        }
      },
      {
        path: '*',
        component: PageNotFound,
        name: 'notFound'
      },
      {
        path: '/confirm_email',
        component: ConfirmEmail,
        name: 'Confrim email',
        meta: {
          title: 'Confirm email'
        }
      },
      {
        path: '/confirm_two_factor',
        component: ConfirmTwoFactor,
        name: 'Confrim two factor',
        meta: {
          title: 'Confirm two factor'
        }
      },
      {
        path: '/register',
        component: Register,
        name: 'Register',
        meta: {
          title: 'Register'
        }
      },
      {
        path: '/login',
        component: Login,
        name: 'Login',
        meta: {
          title: 'Login'
        }
      },
      {
        path: '/agreement_accept',
        component: AgreementAccept,
        name: 'Agreement Accept',
        meta: {
          title: 'Agreement Accept'
        }
      },
      {
        path: '/password_requirements',
        component: PasswordRequirements,
        name: 'Password Requirements',
        meta: {
          title: 'Password Requirements'
        }
      },
      {
        path: '/two_factor_requirement',
        component: TwoFactorRequirement,
        name: 'Two Factor Requirement',
        meta: {
          title: 'Two Factor Requirement'
        }
      },
      {
        path: '/security_question',
        component: ChangeSecurityQuestionPage,
        name: 'Security Question',
        meta: {
          title: 'Security Question'
        }
      },
      {
        path: '/reset_password',
        component: ResetPasswordPage,
        name: 'Reset Password',
        meta: {
          title: 'Reset Password'
        }
      },
      {
        path: '/admin',
        component: AdminPage,
        name: 'Admin',
        meta: {
          title: 'Admin'
        }
      },
      {
        path: '/verify_identity',
        component: VerifyIdentity,
        name: 'Verify Identity',
        meta: {
          title: 'Verify Identity'
        }
      },
      {
        path: '/terms_and_policies',
        component: TermsAndPolicies,
        name: 'Terms and Policies',
        meta: {
          title: 'Terms and Policies'
        }
      }
    ]
  })
}

export const router = createRouter()
