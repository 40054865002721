<template>
  <b-container fluid>
    <b-form @submit.prevent="verifySecurityQuestionHandler" autocomplete="off">
      <b-row class="my-3">
        <b-col sm="5">
          <label for="security-question">Security Question</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="security-question"
            disabled
            type="text"
            :value="securityQuestion"
          />
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col sm="5">
          <label for="security-answer">Answer</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="security-answer"
            required
            type="text"
            v-model="securityAnswer"
          />
        </b-col>
      </b-row>

      <b-row class="d-flex flex-column flex-md-row mt-3">
        <b-col class="mt-2">
          <b-button :disabled="!validForm" @click="verifySecurityQuestion" :style="{ background: brandColor, borderColor: 'transparent' }">
            <b-spinner
              label="Small Spinner"
              small
              v-show="loading"
            ></b-spinner>
            &nbsp;Verify
          </b-button>
        </b-col>
      </b-row>

    </b-form>
  </b-container>
</template>

<script>
import { HTTP } from '../requests/requests'
import { SESSION } from '../utils/session'

import jwt_decode from 'jwt-decode'

async function verifySecurityQuestion(securityAnswer, caveonId, token) {
    try {
      const data = {
        security_answer: securityAnswer,
        caveon_id: caveonId,
        token: token
      }
      const response = await HTTP.post('/api/verify', data)

      return response.data
    } catch (error) {
      return { error }
    }
  }

export default {
  name: 'VerifySecurityQuestion',
  props: {
    token: {
      type: String,
      required: true
    },
    onVerified: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      securityAnswer: '',
      securityQuestion: '',
      caveonId: ''
    }
  },
  created() {
    const decoded = jwt_decode(this.token)

    this.securityQuestion = decoded?.security_question
    this.caveonId = decoded?.caveon_id
  },
  methods: {
    async verifySecurityQuestion() {
      this.loading = true
      const response = await verifySecurityQuestion(this.securityAnswer, this.caveonId, this.token)
      if (response.error) {
        console.error(response.error)
        this.loading = false
        this.$bvToast.toast('Security answer could not be verified', {
          title: 'Failed',
          variant: 'danger',
          noAutoHide: true
        })
        return
      }
      
      this.$bvToast.toast(
        'Security answer has been verified!',
        {
          title: 'Success',
          variant: 'success',
          noAutoHide: true
        }
      )

      this.loading = false
      this.onVerified(response.token)
    }
  },
  computed: {
      brandColor() {
        return SESSION.branding.color
      },
      validForm() {
        if (!this.securityAnswer) return false

        return true
      }
    }
}
</script>

<style scoped>
</style>