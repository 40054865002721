const ACCESS_TOKEN_KEY = 'caveon_id_access_token'
const REFRESH_TOKEN_KEY = 'caveon_id_refresh_token'
const DEVICE_TOKEN_KEY = 'caveon_id_trusted_device_token'
const LAST_ACTIVITY_KEY = 'caveon_id_last_activity'

const factory = () => {
  return new Storage()
}

class Storage {
  constructor(myStorage) {
    this.storage = myStorage ?? window.localStorage
    this.email = ''
  }

  _setItem(key, value) {
    this.storage.setItem(key, value)
  }

  _getItem(key) {
    return this.storage.getItem(key)
  }

  _removeItem(key) {
    this.storage.removeItem(key)
  }

  clear(all) {
    if (all) {
      this.storage.clear()
    } else {
      this.storage.removeItem(ACCESS_TOKEN_KEY)
      this.storage.removeItem(REFRESH_TOKEN_KEY)
    }
  }

  get deviceToken() {
    return this._getItem(`${DEVICE_TOKEN_KEY}_${this.email}`)
  }

  set deviceToken(deviceToken) {
    if (!deviceToken) {
      return this._removeItem(`${DEVICE_TOKEN_KEY}_${this.email}`)
    }

    this._setItem(`${DEVICE_TOKEN_KEY}_${this.email}`, deviceToken)
  }

  get accessToken() {
    return this._getItem(ACCESS_TOKEN_KEY)
  }

  set accessToken(accessToken) {
    if (!accessToken) {
      return this._removeItem(ACCESS_TOKEN_KEY)
    }

    this._setItem(ACCESS_TOKEN_KEY, accessToken)

    const storageEvent = new Event('storage')

    storageEvent.key = ACCESS_TOKEN_KEY

    dispatchEvent(storageEvent)
  }

  get refreshToken() {
    return this._getItem(REFRESH_TOKEN_KEY)
  }

  set refreshToken(refreshToken) {
    if (!refreshToken) {
      return this._removeItem(REFRESH_TOKEN_KEY)
    }

    this._setItem(REFRESH_TOKEN_KEY, refreshToken)
  }

  get lastActivity() {
    return this._getItem(LAST_ACTIVITY_KEY)
  }

  set lastActivity(lastActivity) {
    if (!lastActivity) {
      return this._removeItem(LAST_ACTIVITY_KEY)
    }

    this._setItem(LAST_ACTIVITY_KEY, lastActivity)
  }
}

const storage = factory()

export { factory, Storage, storage, ACCESS_TOKEN_KEY }
