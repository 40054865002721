import FingerprintJS from '@fingerprintjs/fingerprintjs'
import jwt_decode from 'jwt-decode'

import { API } from '../requests/api-cache'
import { setAuthHeader } from '../requests/requests'
import { storage } from '../utils/storage'
import { SESSION } from '../utils/session'
import { router } from '../router'

const fingerprintPromise = FingerprintJS.load()

const setAccessRefreshTokens = (accessToken, refreshToken) => {
  return new Promise((resolve, reject) => {
    try {
      storage.accessToken = accessToken
      storage.refreshToken = refreshToken
    } catch (e) {
      return reject()
    }

    setAuthHeader(accessToken)

    return resolve()
  })
}

const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

const getLoginRedirect = async data => {
  const { loginToken, accessToken, refreshToken, trustedDeviceToken, email } = data

  if (trustedDeviceToken && trustedDeviceToken.length) {
    storage.email = email
    storage.deviceToken = trustedDeviceToken
  }

  if (loginToken) {
    let { statuses } = jwt_decode(loginToken)
    statuses = {
      ...statuses
    }

    if (!statuses.password) {
      return `/password_requirements?loginToken=${loginToken}`
    }
    if (!statuses.twoFactorReq) {
      return `/two_factor_requirement?loginToken=${loginToken}`
    }
    if (!statuses.confirmEmail) {
      return `/confirm_email?loginToken=${loginToken}`
    }
    if (!statuses.twoFactor) {
      return `/confirm_two_factor?loginToken=${loginToken}`
    }
    if (!statuses.agreements) {
      return `/agreement_accept?loginToken=${loginToken}`
    }
    if (!statuses.securityQuestion) {
      return `/security_question?loginToken=${loginToken}`
    }
  }

  if (accessToken && refreshToken) {
    await setAccessRefreshTokens(accessToken, refreshToken)

    return '/'
  }

  return '/login'
}

const getFingerprint = async () => {
  const fingerprint = await fingerprintPromise
  const result = await fingerprint.get()

  return result.visitorId
}

const getOS = () => {
  let os = 'Unknown'

  const { userAgent } = navigator

  if (/Windows/.test(userAgent)) {
    os = 'Windows'
  } else if (/(iPhone|iPad|iPod)/.test(userAgent)) {
    os = 'iOS'
  } else if (/Mac/.test(userAgent)) {
    os = 'Mac OS'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(userAgent)) {
    os = 'Linux'
  }

  return os
}

const logoutUser = (pushToLogin = true) => {
  storage.accessToken = null
  storage.refreshToken = null

  API.clearCache()

  setAuthHeader('')

  postMessage({
    type: 'caveonid_close_modal'
  })

  postMessage({
    type: 'logout'
  })

  if (pushToLogin) {
    router.push({ path: '/login' })
  }
}

const postMessage = (data) => {
  for (const site of SESSION.sites) {
    window.parent.postMessage({
      ...data
    }, site)
  }
}

export { 
  setAccessRefreshTokens, 
  getLoginRedirect, 
  getFingerprint, 
  getOS, 
  postMessage, 
  logoutUser, 
  deepCopy 
}
