import Vue from 'vue'

export const EVENT = new Vue({
  data: {
    globalAlertEvent: 'globalAlertEvent',
    setGlobalUserEvent: 'setGlobalUserEvent'
  },
  methods: {
    globalAlert(args) {
      this.$emit(this.globalAlertEvent, args)
    },
    setGlobalUser(args) {
      this.$emit(this.setGlobalUserEvent, args)
    }
  }
})
