<template>
  <div>
    <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
      <b-navbar-brand href="#" style="color: #fff;">
          <img :src="brandImage" class="logo" />
            Create Account</b-navbar-brand>
      </b-navbar>
    <b-container style="max-width: 950px;">
      <b-card class="mt-3 p-4" no-body>
        <b-card-text>
      <b-form @submit.prevent="register" id="register-form">
        <b-form-group label="First name *" label-for="first-name-input">
          <b-form-input
            id="first-name-input"
            required
            type="text"
            v-model="registerForm.first_name"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Last name *" label-for="last-name-input">
          <b-form-input
            id="last-name-input"
            required
            type="text"
            v-model="registerForm.last_name"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Email *" label-for="email-input">
          <b-form-input
            id="email-input"
            required
            type="email"
            v-model="registerForm.email"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Password *" label-for="password-input">
          <b-form-input
            id="password-input"
            required
            type="password"
            v-model="registerForm.password"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirm Password *"
          label-for="password-confirm-input"
        >
          <b-form-input
            id="password-confirm-input"
            required
            type="password"
            v-model="registerForm.password_confirm"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Security Question *"
          label-for="security-question-input"
        >
          <b-form-input
            id="security-question-input"
            required
            type="text"
            placeholder="What is my favorite color?"
            v-model="registerForm.security_question"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Answer *"
          label-for="security-answer-input"
        >
          <b-form-input
            id="security-answer-input"
            required
            type="text"
            placeholder="Blue"
            v-model="registerForm.security_answer"
          ></b-form-input>
        </b-form-group>
        <b-row class="mt-3">
          <b-col cols="6" class="mt-2">
            <b-button
              form="register-form"
              type="submit"
              class="mr-3 px-4"
              block
              :style="{ background: brandColor, borderColor: 'transparent' }"
            >
              <small v-if="loading" class="text-center m-2">
                <b-spinner label="Loading" style="width: 20px; height: 20px; margin-left: -40px;"></b-spinner>
              </small>
              &nbsp;Create Account
            </b-button>
          </b-col>
          <b-col cols="6" class="mt-2">
            <router-link to="/login" custom v-slot="{ navigate }">
              <b-button @click="navigate" variant="white" block>Cancel</b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-form>
        </b-card-text>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import { CAVEONID_BASE } from '../utils/constants'
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'
  import { storage } from '../utils/storage'

  async function sendRegistration(data) {
    try {
      const response = await HTTP.post(`${CAVEONID_BASE}/auth/register`, data)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Register',
    data() {
      return {
        loading: false,
        positionOptions: [
          { value: '', text: 'Select One...', disabled: true },
          { value: 'item_writer', text: 'Item Writer' }
        ],
        organizationOptions: [
          { value: '', text: 'Select One...', disabled: true },
          { value: 'fake_uuid', text: 'Fake Org' }
        ],
        registerForm: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password_confirm: '',
          security_question: '',
          security_answer: ''
        }
      }
    },
    async created() {
      if (storage.accessToken) {
        return this.$router.replace({ path: '/' })
      }

      await this.$recaptchaLoaded()
      this.$recaptchaInstance.showBadge()
    },
    beforeRouteLeave(to, from, next) {
      this.$recaptchaInstance.hideBadge()
      next()
    },
    methods: {
      async register() {
        if (this.registerForm.password !== this.registerForm.password_confirm) {
          this.$bvToast.toast(
            'Passwords do not match! Please check your passwords and try again.',
            {
              title: 'Warning',
              variant: 'warning',
              noAutoHide: true
            }
          )
          return
        }
        this.loading = true
        const token = await this.$recaptcha('login')
        const register_data = { ...this.registerForm, token: token }
        const { error } = await sendRegistration(register_data)
        this.loading = false
        if (error) {
          this.$bvToast.toast('Registration Failed! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })
          return
        }

        sessionStorage.setItem('registered', true)

        this.redirectHome()
      },
      redirectHome() {
        this.$router.push({ path: '/' })
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      }
    }
  }
</script>

<style scoped></style>
