<template>
  <div v-if="user.caveon_id">
    <div v-if="appAgreement">
      <b-card
        class="m-3 scroll-box"
        :title="appAgreement.name"
        :sub-title="appAgreement.modified_at"
      >
        <b-card-text v-markdown="appAgreement.content" />
      </b-card>
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <b-button 
              class="mt-3 mr-3" 
              :style="{ background: brandColor, borderColor: 'transparent' }"
              :disabled="loading.appAgreement"
              @click="acceptAppAgreement"
            >
              Accept
            </b-button>

            <b-button 
              class="mt-3 mr-3" 
              :style="{ background: brandColor, borderColor: 'transparent' }"
              :disabled="loading.appAgreement"
              @click="declineAppAgreement"
            >
              Decline
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <TwoFactorAuth :user="user" />
      <b-modal id="change-password-modal" title="Change Password" hide-footer>
        <ChangePassword :onPasswordChanged="changePasswordHandler" is-modal />
      </b-modal>
      <b-modal id="change-security-question-modal" title="Change Security Question" hide-footer>
        <ChangeSecurityQuestion :onSecurityQuestionChanged="securityQuestionChanged" is-modal />
      </b-modal>
      <b-modal id="set-internal-login-data-modal" :title="internalLoginModalTitle" hide-footer>
        <b-form-group label="Password *" label-for="password-input">
            <b-form-input id="password-input" type="password" v-model="internalLoginData.password" />
        </b-form-group>

        <b-form-group label="Confirm Password *" label-for="password-confirm-input">
          <b-form-input id="password-confirm-input" type="password" v-model="internalLoginData.confirmPassword" />
        </b-form-group>

        <div v-if="!user.security_question">
          <b-form-group label="Security Question *" label-for="security-question-input">
            <b-form-input id="security-question-input" type="text" placeholder="What is my favorite color?" v-model="internalLoginData.securityQuestion" />
          </b-form-group>

          <b-form-group label="Answer *" label-for="security-answer-input">
            <b-form-input id="security-answer-input" type="text" placeholder="Blue" v-model="internalLoginData.securityAnswer" />
          </b-form-group>
        </div>

        <b-row class="mt-3">
          <b-col cols="6" class="mt-2">
            <b-button 
              @click="submitInternalLoginData" 
              class="mr-3 px-4" 
              block 
              :style="{ background: brandColor, borderColor: 'transparent' }" 
              :disabled="!internalLoginDataValid"
            >
              <small v-if="loading.internal" class="text-center m-2">
                <b-spinner label="Loading" style="width: 20px; height: 20px; margin-left: -40px;" />
              </small>
              &nbsp;Submit
            </b-button>
          </b-col>
          <b-col cols="6" class="mt-2">
            <b-button @click="closeInternalLoginDataModal" variant="white" block>Cancel</b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="change-profile-modal" title="Change Profile" hide-footer>
        <b-row>
          <b-col sm="5">
            <label for="input-small">First name</label>
          </b-col>
          <b-col sm="7">
            <b-input type="text" v-model="editableProfile.first_name"></b-input>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col sm="5">
            <label for="input-small">Last name</label>
          </b-col>
          <b-col sm="7">
            <b-input type="text" v-model="editableProfile.last_name"></b-input>
          </b-col>
        </b-row>

        <b-row class="d-flex flex-column flex-md-row mt-3">
          <b-col cols="6" class="mt-2">
            <b-button block @click="saveProfile" :style="{ background: brandColor, borderColor: 'transparent' }"
              >Submit</b-button
            >
          </b-col>
          <b-col cols="6" class="mt-2">
            <b-button block @click="cancelEdit" variant="white"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </b-modal>

      <ChangePhone :user="user" />
        <b-navbar type="dark" class="mb-4 mr-0 pr-0" :style="{ background: brandColor }">
            <b-row class="w-100 mr-0 pr-0">
              <b-col cols="12"><img :src="brandImage" class="logo float-left mt-0 pt-0" /> <b-navbar-brand href="#" style="color: #fff;">{{ fullName }}</b-navbar-brand></b-col>
              <b-col cols="12" class="pr-0">
                <b-button-group class="d-flex">
                  <router-link to="/admin" custom v-slot="{ navigate }" v-if="isAdmin">
                    <b-button @click="navigate" variant="white" size="sm">Administrator Tools</b-button>
                  </router-link>
                  <b-button @click="logoutUser" variant="white" size="sm">Log Out</b-button>
                </b-button-group>
              </b-col>
            </b-row>
        </b-navbar>
        <b-container>
          <b-card no-body>
            <b-tabs card>
              <b-tab title="Profile">
                <b-card-text>

                    <b-row class="my-3" v-if="hasOrg">
                      <b-col md="4" lg="3">
                        <label for="input-small">Organization</label>
                      </b-col>
                      <b-col>
                        <b>{{ user.org.name }}</b>
                      </b-col>
                    </b-row>

                    <b-row class="my-3">
                      <b-col md="4" lg="3">
                        <label for="input-small">Email address</label>
                      </b-col>
                      <b-col>
                        <b>{{ user.email }}</b>
                      </b-col>
                    </b-row>

                    <b-row class="mb-3">
                      <b-col md="4" lg="3">
                        <label for="input-small">Name</label>
                      </b-col>
                      <b-col class="d-flex justify-content-between align-items-center">
                        <b>{{ user.first_name }} {{ user.last_name }}</b>

                        <b-button
                          variant="white"
                          size="sm"
                          v-b-modal.change-profile-modal
                        >
                          Change
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row class="mt-3">
                      <b-col md="4" lg="3" class="d-flex align-items-center">
                        <label for="input-small">Phone</label>
                      </b-col>
                      <b-col class="d-flex justify-content-between align-items-center">
                        <b>{{ user.phone }}</b>

                        <b-button
                            variant="white"
                            size="sm"
                            v-b-modal.change-phone-modal
                          >
                            Change
                          </b-button>
                      </b-col>
                    </b-row>

                    <b-row v-if="!user.phone_confirmed_at && user.phone">
                      <b-col md="4" lg="3" />
                      <b-col>
                        <small class="d-flex align-items-center">
                          This phone number has not been verified.
                          <b-button variant="white" size="sm" class="ml-2" @click="verifyPhone">Verify</b-button>
                        </small>
                      </b-col>
                    </b-row>

                    <div>
                      <hr>
                      <b-row class="mt-2 pt-2">
                        <b-col md="4" lg="3" class="d-flex align-items-center">
                          Two Factor Authentication
                        </b-col>
                        <b-col class="d-flex justify-content-between align-items-center">
                          <span class="py-1">
                            <b :class="twoFactorStatus.class">{{ twoFactorStatus.text }}</b>
                          </span>
                          <b-button
                            variant="white"
                            size="sm"
                            v-b-modal.two-factor-auth-modal
                          >
                            {{ twoFactorButtonText }}
                          </b-button>
                        </b-col>
                      </b-row>
                      <div v-if="user.password_changed_at">
                        <hr>
                        <b-row class="mt-2 pt-2">
                          <b-col md="4" lg="3" class="d-flex align-items-center">
                            Security Question
                          </b-col>
                          <b-col class="d-flex justify-content-between align-items-center">
                            <b>{{ user.security_question }}</b>

                            <b-button
                              variant="white"
                              size="sm"
                              v-b-modal.change-security-question-modal
                            >
                              Change
                            </b-button>
                          </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mt-2 pt-2">
                          <b-col md="4" lg="3" class="d-flex align-items-center">
                            Password Strength
                          </b-col>
                          <b-col class="d-flex justify-content-between align-items-center">
                            <b>{{ myPwStrength }} / 10</b>
                            <b-button
                              variant="white"
                              size="sm"
                              v-b-modal.change-password-modal
                            >
                              Change
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                      <div v-if="!user.password_changed_at && !user.org.settings.is_saml_login">
                        <hr>
                        <b-row class="mt-2 pt-2">
                          <b-col class="d-flex justify-content-between align-items-center">
                            {{ externalAccountMessage }}
                            <b-button
                              variant="white"
                              size="sm"
                              @click="openInternalLoginDataModal"
                            >
                              Change
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab title="Agreements" @click="fetchAgreements">
                <AgreementListing
                  v-if="acceptedAgreements && !loading['agreements']"
                  class="mt-3"
                  :agreements="acceptedAgreements"
                  :fields="agreementFields"
                />
                <div v-else class="text-center m-2">
                  <b-spinner label="Loading"></b-spinner>
                </div>
              </b-tab>
              <b-tab title="Data" @click="fetchMyData">
                <MyData v-if="showMyData" />
              </b-tab>
              <b-tab v-if="hasOrg" title="Organization">
                You are a member of {{ user.org.name }} organization.
              </b-tab>
              <b-tab title="Logs" @click="fetchLogs">
                <Logs v-if="showLogs" />
              </b-tab>
            </b-tabs>
          </b-card>
          <AppLinks :isPreview="isPreview" />
      </b-container>
    </div>
  </div>
  <div v-else>
    <div class="text-center m-2">
      <b-spinner label="Loading"></b-spinner>
    </div>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { EVENT } from '../utils/eventBus'
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'
  import { logoutUser, postMessage } from '../utils/helpers'
  import { storage } from '../utils/storage'

  import AgreementListing from './AgreementListing'
  import ChangePassword from './ChangePassword'
  import ChangePhone from './ChangePhone.vue'
  import ChangeSecurityQuestion from './ChangeSecurityQuestion.vue'
  import Logs from './Logs'
  import TwoFactorAuth from './TwoFactorAuth'
  import MyData from './MyData'
  import AppLinks from './AppLinks.vue'


  async function updateUserProfile(profile) {
    const { first_name, last_name } = profile
    const payload = {
      first_name,
      last_name
    }

    try {
      const response = await HTTP.put(`/api/users/${profile.caveon_id}`, payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function updateUserApps(profile, parentOrigin, client) {
    if (!parentOrigin) return

    const payload = {
      origin: parentOrigin,
      client
    }

    try {
      const response = await HTTP.put(`/api/users/${profile.caveon_id}/apps`, payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function getAppAgreement(client) {
    try {
      const url = `/api/agreements/app_agreement?client=${ client }`
      const response = await HTTP.get(url)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function acceptAppAgreementRequest(client) {
    try {
      const url = '/api/agreements/app_agreement_accept'
      const payload = { client }
      const response =  await HTTP.post(url, payload)
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function submitInternalLoginDataRequest(internalLoginData) {
    const url = '/api/auth/set_internal_login'

    const payload = { ...internalLoginData }

    try {
      const response = await HTTP.post(url, payload)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Dashboard',
    components: {
      AgreementListing,
      ChangePassword,
      ChangePhone,
      ChangeSecurityQuestion,
      Logs,
      TwoFactorAuth,
      MyData,
      AppLinks
    },
    props: {
      user: {
        type: Object
      }
    },
    watch: {
      user: {
        handler(updatedUser) {
          this.editableProfile = { ...updatedUser }
          this.twoFactorEnabled = updatedUser.enable_multi_factor_auth
        },
        deep: true
      }
    },
    async created() {
      if (!storage.accessToken) {
        return this.$router.replace({ path: '/login' })
      }

      if (!this.user.caveon_id) {
        const user = await API.getMe(true)
        const { client } = SESSION

        let closeModal = true

        if (window.location !== window.parent.location || client) {
          await updateUserApps(user, document.referrer, client)

          if (client) {
            const { data } = await getAppAgreement(client)

            if (data.agreement) {
              closeModal = false

              data.agreement.modified_at = this.$moment.utc(data.agreement.modified_at).local().format('L LT')

              this.appAgreement = data.agreement

              postMessage({
                type: 'caveonid_lock_modal'
              })
            }
          }
        }

        this.emitUserToParent(user)

        if (closeModal) {
          postMessage({
            type: 'caveonid_close_modal'
          })
        }
      }

      this.editableProfile = { ...this.user }

      this.twoFactorEnabled = this.user?.enable_multi_factor_auth
    },
    data() {
      return {
        loading: {
          profile: true,
          agreements: true,
          internal: false,
          appAgreement: false
        },
        agreementResponses: [],
        agreements: [],
        agreementFields: [
          {
            key: 'modified_at',
            label: 'Accepted On',
            sortable: true,
            formatter: date =>
              this.$moment
                .utc(date)
                .local()
                .format('L LT')
          },
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'content',
            sortable: true
          }
        ],
        twoFactorEnabled: false,
        updatingTwoFactorStatus: false,
        editableProfile: {},
        internalLoginData: {},
        appAgreement: null,
        showMyData: false,
        showLogs: false,
        logoutUser
      }
    },
    methods: {
      getAgreement(id) {
        return this.agreements.find(agreement => agreement.id === id)
      },
      async fetchAgreements() {
        const [agreements, agreementResponses] = await Promise.all([
          API.getAgreements(),
          API.getAgreementResponses()
        ])

        this.agreements = agreements
        this.agreementResponses = agreementResponses
        this.loading['agreements'] = false
      },
      fetchMyData() {
        this.showMyData = true
      },
      fetchLogs() {
        this.showLogs = true
      },
      cancelEdit() {
        this.editableProfile = { ...this.user }

        this.$bvModal.hide('change-profile-modal')
      },
      async saveProfile() {
        this.$bvModal.hide('change-profile-modal')

        const saved = await updateUserProfile(this.editableProfile)

        if (saved.error) {
          this.$bvToast.toast('Failed to save! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })

          return
        }

        const { safeUser: { first_name, last_name } } = saved

        API.updateMe('first_name', first_name)

        const updatedUser = API.updateMe('last_name', last_name)

        this.emitUserToParent(updatedUser)
      },
      securityQuestionChanged(newQuestion) {
        const updatedUser = API.updateMe('security_question', newQuestion)

        this.emitUserToParent(updatedUser)

        this.$bvModal.hide('change-security-question-modal')
        this.$bvToast.toast(
          'Security question updated!',
          {
            title: 'Success',
            variant: 'success',
            noAutoHide: false
          }
        )
      },
      emitUserToParent(updatedUser) {
        this.$emit('user-updated', updatedUser)
      },
      changePasswordHandler({ pwStrength }) {
        if (pwStrength) {
          this.emitUserToParent(API.updateMe('pw_strength', pwStrength))
          this.$bvModal.hide('change-password-modal')
        }
      },
      async submitInternalLoginData() {
        this.loading.internal = true

        const { data, error } = await submitInternalLoginDataRequest(this.internalLoginData)

        this.loading.internal = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to save.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        API.updateMe('password_changed_at', data.password_changed_at)

        const updatedUser = API.updateMe('security_question', data.security_question)

        EVENT.setGlobalUser(updatedUser)

        this.closeInternalLoginDataModal()
      },
      openInternalLoginDataModal() {
        this.internalLoginData = {}
        this.$bvModal.show('set-internal-login-data-modal')
      },
      closeInternalLoginDataModal() {
        this.$bvModal.hide('set-internal-login-data-modal')
      },
      verifyPhone() {
        EVENT.$emit('verify-phone')
      },
      async acceptAppAgreement() {
        const { client } = SESSION

        this.loading.appAgreement = true

        const { error } = await acceptAppAgreementRequest(client)

        this.loading.appAgreement = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to accept agreement.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        postMessage({
          type: 'caveonid_unlock_modal'
        })

        this.appAgreement = null
      },
      declineAppAgreement() {
        postMessage({
          type: 'caveonid_unlock_modal'
        })

        logoutUser()
      }
    },
    computed: {
      acceptedAgreements() {
        const acceptedAgreements = []
        for (const response of this.agreementResponses) {
          if (response.accepted) {
            const agreement = this.getAgreement(response.agreement_id)
            
            if (agreement) {
              acceptedAgreements.push({
                ...response,
                name: agreement.name,
                content: agreement.content
              })
            }
          }
        }
        return acceptedAgreements
      },
      fullName() {
        return `${this.user.first_name} ${this.user.last_name}`
      },
      twoFactorStatus() {
        let status = {
          text: 'Enabled',
          class: 'text-green'
        }

        if (!this.twoFactorEnabled) {
          status = {
            text: 'Disabled',
            class: 'text-red'
          }
        }

        return status
      },
      isAdmin() {
        return this.user && this.user.roles.includes('admin')
      },
      hasOrg() {
        return !this.user.org.settings.is_default
      },
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      },
      myPwStrength() {
        const str = this.user ? this.user.pw_strength : 0

        return str * 10
      },
      internalLoginDataValid() {
        const { password, confirmPassword, securityQuestion, securityAnswer } = this.internalLoginData

        const securityQuestionValid = this.user.security_question || (securityQuestion && securityAnswer)

        return password && (password === confirmPassword) && securityQuestionValid
      },
      externalAccountMessage() {
        let message = 'To login without the use of an external authenticator you will need to set a password'

        if (!this.user.security_question) {
          message += ' and security question'
        }

        return message + '.'
      },
      internalLoginModalTitle() {
        let title = 'Set Password'

        if (!this.user.security_question) {
          title += ' and Security Question'
        }

        return title
      },
      twoFactorButtonText() {
        if (this.user.enable_multi_factor_auth) {
          return 'Change'
        }

        return 'Enable'
      },
      isPreview() {
        try {
          return Boolean(window.location.origin.includes('preview'))
        } catch {
          return false
        }
      }
    },
    filters: {
      round(value) {
        return value.toFixed(2)
      }
    }
  }
</script>

<style scoped>
.text-green {
  color: green;
}

.text-red {
  color: red;
}
</style>
