<template>
  <b-table 
    :items="orgs" 
    :fields="fields" 
    hover 
    responsive
    small
    striped
    bordered
    class="mt-3"
  >
    <template #cell(actions)="row">
      <b-button variant="white" size="sm" class="float-right mr-1" @click="edit(row)">Edit</b-button>
    </template>
  </b-table>
</template>

<script>
  export default {
    name: 'OrgListing',
    props: {
      orgs: {
        type: Array
      },
      edit: {
        type: Function
      }
    },
    data() {
      return {
        fields: [
          { key: 'name' },
          { key: 'actions', label: '', headerTitle: 'Actions' }
        ]
      }
    }
  }
</script>

<style scoped>
</style>
