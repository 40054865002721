import axios from 'axios'
import { storage } from '../utils/storage'


export function axiosFactory({ accessToken }={}) {
  const headers = {}

  if (accessToken && accessToken.length) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const myAxios = axios.create({ headers })

  myAxios.interceptors.response.use(response => {
    const freshToken = response.headers['x-caveonid-fresh-token']

    if (freshToken) {
      myAxios.defaults.headers.common['Authorization'] = `Bearer ${freshToken}`
      storage.accessToken = freshToken
    }

    return response
  }, async error => {
    return Promise.reject(error)
  })

  return myAxios
}
