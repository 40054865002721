const BRANDING_LOOKUP = {
    core: {
        color: '#032e59',
        image: 'logo-core-sm.png'
    },
    scorpion: {
        color: '#6c456e',
        image: 'logo-scorpion-sm.png'
    }
}

function setClientAndBranding(client) {
    const branding = BRANDING_LOOKUP[client]

    if (branding) {
        SESSION.branding = { ...branding }
    }

    SESSION.client = client
}

export const SESSION = {
    branding: {
        color: '#333333',
        image: 'logo-caveon-sm.png'
    },
    sites: [],
    setClientAndBranding
}