<template>
  <div>
    <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
      <b-navbar-brand href="#" style="color: #fff;">
        <img :src="brandImage" class="logo" />
        Verify Identity
      </b-navbar-brand>
    </b-navbar>
    <b-container style="max-width: 800px;">
      <b-card class="mt-3 p-4" no-body>
        <b-card-text>
          <VerifySecurityQuestion :token="token" v-if="!loading" />
        </b-card-text>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import VerifySecurityQuestion from './VerifySecurityQuestion.vue'

import { SESSION } from '../utils/session'

import jwt_decode from 'jwt-decode'

export default {
  name: 'VerifyIdentity',
  components: {
    VerifySecurityQuestion
  },
  data() {
    return {
      loading: true,
      token: null
    }
  },
  async created() {
    this.token = this.$route.query.token

    try {
      jwt_decode(this.token)

      this.loading = false
    } catch (error) {
      this.$router.replace({ path: '/login' })
    }
  },
  computed: {
    brandColor() {
      return SESSION.branding.color
    },
    brandImage() {
      return require('../assets/' + SESSION.branding.image)
    }
  }
}
</script>

<style scoped>
</style>