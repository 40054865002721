<template>
  <div>
    <b-row :class="emailWrapperClass" v-if="admin">
      <b-col sm="3">
        <label for="user-email-input">User Email</label>
      </b-col>
      <b-col sm="9">
        <b-input-group>
          <b-input
            v-model="enteredEmail"
            id="user-email-input"
            placeholder="Enter email address"
            @keypress.enter="getUserLogs(true)"
          ></b-input>
          <b-input-group-append>
            <b-button
              variant="primary"
              :disabled="loading"
              @click="getUserLogs(true)"
              >Search</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <div v-if="!loading">
      <b-table
        :fields="fields"
        :items="logs"
        id="logs-table"
        hover
        responsive
        bordered
        small
        striped
        show-empty
        v-if="!admin || searched"
      >
        <template #empty="scope">
          <div>{{ scope.emptyText }}</div>
        </template>
        <template #cell(event)="scope">
          <div>{{ scope.item.event }}</div>
          <div v-if="scope.item.info">
            <small>{{ scope.item.info.ipAddress }}</small>
            <small>&nbsp;:&nbsp;</small>
            <small>{{ scope.item.info.location }}</small>
            <small>&nbsp;:&nbsp;</small>
            <small>{{ scope.item.info.os }}</small>
          </div>
        </template>
      </b-table>
      <b-pagination
        :total-rows="total"
        :per-page="perPage"
        :value="page"
        aria-controls="logs-table"
        v-if="total"
        @change="onPageChange"
      />
    </div>
    <div v-else class="text-center m-2">
      <b-spinner />
    </div>
  </div>
</template>

<script>
import { HTTP } from '../requests/requests'

async function getMyLogs(page, perPage) {
  try {
    const url = `/api/logs?page=${page}&per_page=${perPage}`
    const response = await HTTP.get(url)
    return response
  } catch (error) {
    return { error }
  }
}

async function getUserLogs(email, page, perPage) {
  try {
    const url = `/api/logs/user_logs?page=${page}&per_page=${perPage}`
    const payload = { email }
    const response = await HTTP.post(url, payload)
    return response
  } catch (error) {
    return { error }
  }
}

export default {
  name: 'Logs',
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      page: 1,
      perPage: 100,
      total: 0,
      loading: false,
      searched: false,
      logs: [],
      fields: [
        {
          key: 'created_at',
          label: 'Date',
          formatter: (date) => this.$moment.utc(date).local().format('L LT')
        },
        {
          key: 'event'
        }
      ],
      enteredEmail: null
    }
  },
  created() {
    if (this.email) {
      return this.getUserLogs(true)
    }

    if (!this.admin) {
      this.getMyLogs()
    }
  },
  methods: {
    clearState() {
      this.page = 1
      this.total = 0
      this.logs = []
      this.loading = false
      this.searched = false
    },
    async getMyLogs() {
      this.loading = true

      const { data, error } = await getMyLogs(this.page, this.perPage)

      if (error) {
        this.clearState()
        return this.$bvToast.toast('Failed to get logs.', {
          title: 'Failed',
          variant: 'danger',
          noAutoHide: true
        })
      }

      this.logs = data.results
      this.total = data.total

      this.loading = false
    },
    async getUserLogs(resetPage = false) {
      if (!this.userEmail || this.loading) return

      if (resetPage) {
        this.page = 1
      }

      this.loading = true

      const { data, error } = await getUserLogs(
        this.userEmail,
        this.page,
        this.perPage
      )

      if (error) {
        this.clearState()
        return this.$bvToast.toast('Failed to get user logs.', {
          title: 'Failed',
          variant: 'danger',
          noAutoHide: true
        })
      }

      this.logs = data.results
      this.total = data.total

      this.searched = true
      this.loading = false
    },
    onPageChange(page) {
      this.page = page

      if (this.admin) {
        return this.getUserLogs()
      }

      this.getMyLogs()
    }
  },
  computed: {
    emailWrapperClass() {
      return this.searched ? 'mb-4' : ''
    },
    userEmail() {
      return this.email || this.enteredEmail
    }
  }
}
</script>

<style scoped>
</style>
