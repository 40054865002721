<template>
  <div>
    <b-navbar
      toggleable="md"
      type="dark"
      class="mb-4"
      :style="{ background: brandColor }"
    >
      <b-navbar-brand href="#" style="color: #fff;">
        <img :src="brandImage" class="logo" />
        Reset Password</b-navbar-brand
      >
    </b-navbar>
    <b-container style="max-width: 950px;">
      <b-card class="mt-3 p-4 red-border" v-if="unFulfilledRequirements.length" no-body>
        <b-card-text>
          <UnfulfilledPwdReqs :reqs="unFulfilledRequirements" />
        </b-card-text>
      </b-card>
      <b-card class="mt-3 p-4" no-body>
        <b-card-text>
          <VerifySecurityQuestion
            v-if="showSecurityQuestionForm"
            :onVerified="securityQuestionVerified"
            :token="token"
          />
          <b-form
            v-if="showPasswordResetForm"
            @submit.prevent="changePasswordHandler"
            novalidate
            autocomplete="off"
          >
            <b-form-group
              label="New Password"
              label-for="new-password"
              class="formText"
              label-cols-md="4"
              label-cols-lg="3"
              content-cols-md="8"
              content-cols-lg="9"
            >
              <b-form-input
                autofocus
                id="new-password"
                required
                type="password"
                v-model="newPassword"
              />
            </b-form-group>

            <b-form-group
              label="Retype the New Password"
              label-for="new-password-again"
              class="formText"
              label-cols-md="4"
              label-cols-lg="3"
              content-cols-md="8"
              content-cols-lg="9"
            >
              <b-form-input
                id="new-password-again"
                required
                type="password"
                v-model="newPasswordAgain"
              />
            </b-form-group>

            <b-row class="d-flex flex-column flex-md-row my-3">
              <b-col class="mt-2">
                <b-button
                  :disabled="!validForm"
                  type="submit"
                  block
                  :style="{
                    background: brandColor,
                    borderColor: 'transparent'
                  }"
                  >Change password</b-button
                >
              </b-col>

              <b-col class="mt-2">
                <b-button block @click="cancel" type="button" variant="white"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </b-form>

          <b-form
            v-if="showEmailForm"
            @submit.prevent="submitEmailHandler"
            novalidate
            class="mb-4"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                autofocus
                id="email"
                required
                type="email"
                v-model="email"
              />
            </b-form-group>
            <b-row class="d-flex flex-column flex-md-row mt-3">
              <b-col cols="6" class="mt-2">
                <b-button
                  block
                  :disabled="!validForm"
                  type="submit"
                  :style="{
                    background: brandColor,
                    borderColor: 'transparent'
                  }"
                  >Submit</b-button
                >
              </b-col>
              <b-col cols="6" class="mt-2">
                <b-button block @click="cancel" type="button" variant="white"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </b-form>

          <div class="text-center">
            <b-spinner v-if="loading" label="Loading"></b-spinner>
            <p v-if="message && message.length">{{ message }}</p>
          </div>
        </b-card-text>
      </b-card>
      <b-button
        type="link"
        variant="link"
        size="sm"
        @click="clearState"
        class="mt-3 pr-0"
        >Start over</b-button
      >
    </b-container>
  </div>
</template>

<script>
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'
  import { storage } from '../utils/storage'

  import VerifySecurityQuestion from './VerifySecurityQuestion.vue'
  import UnfulfilledPwdReqs from './UnfulfilledPwdReqs.vue'

  import jwt_decode from 'jwt-decode'

  const STATE = {
    token: '',
    email: '',
    appName: 'Caveon ID',
    message: '',
    loading: false,
    emailFormIsHidden: false,
    passwordResetFormIsHidden: true,
    securityQuestionFormIsHidden: true,
    newPassword: '',
    newPasswordAgain: '',
    securityQuestion: '',
    unFulfilledRequirements: []
  }

  async function resetPassword(passwordData, token) {
    try {
      const payload = {
        token,
        ...passwordData
      }
      const response = await HTTP.post('/api/auth/password_reset', payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function requestPasswordReset(payload) {
    try {
      const response = await HTTP.post('/api/auth/request_password_reset', payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ResetPasswordPage',
    components: {
      VerifySecurityQuestion,
      UnfulfilledPwdReqs
    },
    data: () => {
      return { ...STATE }
    },
    created() {
      if (storage.accessToken) {
        return this.$router.replace({ path: '/' })
      }

      this.token = this.$route.query.token

      if (!this.token) return

      try {
        const { verified } = jwt_decode(this.token)

        this.securityQuestionFormIsHidden = verified

        this.passwordResetFormIsHidden = false
      } catch (error) {
        this.$router.replace({ path: '/login' })
      }
    },
    methods: {
      async submitEmailHandler() {
        this.loading = true
        this.emailFormIsHidden = true

        const response = await requestPasswordReset({ email: this.email })

        this.loading = false

        if (response.error) {
          this.message = 'There was a problem with the request'
        } else {
          this.message = 'Check your email for recovery instructions'
        }
      },
      async changePasswordHandler() {
        const passwordData = {
          newPassword: this.newPassword,
          newPasswordAgain: this.newPasswordAgain
        }

        this.loading = true
        const response = await resetPassword(passwordData, this.token)
        this.loading = false

        if (response.error) {
          console.error(response.error)
          this.$bvToast.toast('Error', {
            title: 'Error',
            variant: 'danger',
            noAutoHide: false
          })
          return
        }

        if (response?.reqs) {
          this.unFulfilledRequirements = response.reqs
          return
        }

        this.$bvToast.toast('Password changed! Redirecting to login...', {
          title: 'Success',
          variant: 'success',
          noAutoHide: false
        })

        setTimeout(this.cancel, 3000)
      },
      cancel() {
        this.$router.push('/login')
      },
      clearState() {
        if (Object.keys(this.$route.query).length) {
          this.$router.replace({ 'query': null })
        }

        for (const [key, value] of Object.entries(STATE)) {
          this[key] = value
        }
      },
      securityQuestionVerified(token) {
        this.securityQuestionFormIsHidden = true
        this.token = token
      }
    },
    computed: {
      validForm() {
        return true
      },
      showEmailForm() {
        return !this.emailFormIsHidden && this.passwordResetFormIsHidden && this.securityQuestionFormIsHidden
      },
      showPasswordResetForm() {
        return !this.passwordResetFormIsHidden && this.securityQuestionFormIsHidden
      },
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      },
      showSecurityQuestionForm() {
        return !this.securityQuestionFormIsHidden 
      }
    }
  }
</script>

<style scoped>
  .red-border {
    border-color: red;
  }
</style>
