<template>
  <div>
    <b-button @click="add" size="sm" :style="{ background: brandColor, borderColor: 'transparent' }">
      <font-awesome-icon icon="plus" />&nbsp;Add an Organization
    </b-button>

    <b-modal id="org-modal" :title="modalTitle" hide-footer>
      <OrgEdit :org="selected" @update="onUpdate" @delete="onDelete" @hide-modal="hideModal" />
    </b-modal>

    <OrgListing :orgs="orgs" :edit="edit" v-if="orgs.length" />

      <div v-if="loading" class="text-center m-2">
        <b-spinner label="Loading"></b-spinner>
      </div>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { SESSION } from '../utils/session'

  import OrgListing from './OrgListing'
  import OrgEdit from './OrgEdit'

  export default {
    name: 'Orgs',
    components: {
      OrgListing,
      OrgEdit
    },
    data() {
      return {
        loading: false,
        orgs: [],
        selected: {},
        selectedIndex: null
      }
    },
    async created() {
      this.orgs = await API.getOrgs()
    },
    methods: {
      add() {
        this.selectedIndex = null
        this.selected = { name: '', settings: {} }
        this.$bvModal.show('org-modal')
      },
      onUpdate(org) {
        if (this.selectedIndex === null) {
          this.orgs.unshift(org)
        } else {
          this.orgs.splice(this.selectedIndex, 1, org)
        }
        this.hideModal()
      },
      onDelete() {
        this.orgs.splice(this.selectedIndex, 1)
        this.hideModal()
      },
      hideModal() {
        this.$bvModal.hide('org-modal')
      },
      edit(row) {
        this.selectedIndex = row.index
        this.selected = row.item
        this.$bvModal.show('org-modal')
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      modalTitle() {
        return this.selected.id ? 'Edit Organization' : 'New Organization'
      }
    }
  }
</script>

<style scoped></style>
