<template>
  <div>
    <b-card class="mb-4 mt-2 p-4 red-border" v-if="reqs.length" no-body>
      <b-card-text>
        <UnfulfilledPwdReqs :reqs="reqs" />
      </b-card-text>
    </b-card>
    <b-form @submit.prevent="changePasswordHandler" novalidate v-if="!loading" autocomplete="off">
      <b-row>
        <b-col sm="5">
          <label for="current-password">Current Password</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            autofocus
            id="current-password"
            required
            type="password"
            v-model="currentPassword"
          />
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col sm="5">
          <label for="new-password">New Password</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="new-password"
            required
            type="password"
            v-model="newPassword"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="5">
          <label for="new-password-again">New Password Again</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            id="new-password-again"
            required
            type="password"
            v-model="newPasswordAgain"
          />
        </b-col>
      </b-row>
      <b-row class="d-flex flex-column flex-md-row mt-3">
        <b-col cols="6" class="mt-2">
          <b-button block :disabled="!validForm" type="submit" :style="{ background: brandColor, borderColor: 'transparent' }"
            >Submit</b-button
          >
        </b-col>
        <b-col cols="6" class="mt-2" v-if="!hideCancel">
          <b-button block @click="cancel" type="button" variant="white"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-form>
    <div v-if="loading" class="text-center m-2">
      <b-spinner label="Loading"></b-spinner>
    </div>
  </div>
</template>

<script>
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'

  import UnfulfilledPwdReqs from './UnfulfilledPwdReqs.vue'

  async function changePasswordLoginToken(passwordData, loginToken) {
    try {
      const payload = {
        loginToken,
        ...passwordData
      }
      const response = await HTTP.post('/api/auth/change_password_login_token', payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  async function changePassword(passwordData) {
    try {
      const payload = {
        ...passwordData
      }
      const response = await HTTP.post('/api/auth/change_password', payload)

      return response.data
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'ChangePassword',
    components: {
      UnfulfilledPwdReqs
    },
    props: {
      hideCancel: {
        type: Boolean,
        required: false
      },
      isModal: {
        type: Boolean,
        required: false
      },
      loginToken: {
        type: String,
        required: false
      },
      onPasswordChanged: {
        type: Function,
        required: false,
        default: () => {}
      },
      reqs: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        loading: false,
        currentPassword: '',
        newPassword: '',
        newPasswordAgain: ''
      }
    },
    methods: {
      async changePasswordHandler() {
        this.loading = true
        const passwordData = {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordAgain: this.newPasswordAgain
        }

        let changePw
        if (this.loginToken) {
          changePw = changePasswordLoginToken
        } else {
          changePw = changePassword
        }

        // they both need the response data to do different things and they both make different reqs
        // so separate out the handler function entirely

        const responseData = await changePw(passwordData, this.loginToken)

        this.clearForm()
        this.loading = false

        if (responseData.error) {
          console.error(responseData.error)
          this.$bvToast.toast(
            responseData.error.response.data,
            {
              title: 'Error',
              variant: 'danger',
              noAutoHide: false
            }
          )
          return
        }

        if (responseData?.reqs) {
          this.reqs = responseData.reqs
          return
        }

        passwordData.pwStrength = responseData.pw_strength
        this.onPasswordChanged(passwordData)
      },
      cancel() {
        if (this.isModal) {
          return this.$bvModal.hide('change-password-modal')
        }

        this.$router.push({ path: '/' })
      },
      clearForm() {
        this.currentPassword = ''
        this.newPassword = ''
        this.newPasswordAgain = ''
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      validForm() {
        if (!this.currentPassword) return
        if (!this.newPassword) return
        if (!this.newPasswordAgain) return

        return this.newPassword === this.newPasswordAgain && this.currentPassword !== this.newPassword
      }
    }
  }
</script>

<style scoped>
  .underline {
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid #efefef;
  }
  .red-border {
    border-color: red;
  }
</style>
