<template>
    <div>
        <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
            <b-navbar-brand href="#" style="color: #fff;">
                <img :src="brandImage" class="logo" />
                Terms and Policies
            </b-navbar-brand>
        </b-navbar>

        <b-container style="max-width: 950px;" v-if="!loading">
            <b-card class="mt-3 p-4" no-body>
                <b-card-text v-markdown="content" />
            </b-card>
        </b-container>

        <div v-else class="d-flex justify-content-center py-4">
            <b-spinner />
        </div>
    </div>
</template>

<script>
import { EVENT } from '../utils/eventBus'
import { HTTP } from '../requests/requests'
import { SESSION } from '../utils/session'

async function getAgreement() {
    try {
        const url = '/api/agreements/terms_and_policies'

        const response = await HTTP.get(url)

        return { data: response.data }
    } catch (error) {
        return { error }
    }
}

export default {
    name: 'TermsAndPolicies',
    async created() {
        const { data, error } = await getAgreement()

        this.loading = false

        if (error) {
            const globalAlertData = {
                message: 'Failed to load terms and policies.',
                variant: 'danger'
            }

          return EVENT.globalAlert(globalAlertData)
        }

        this.content = data.content
    },
    data() {
        return {
            loading: true,
            content: ''
        }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      }
    }
}
</script>

<style lang="scss" scoped>
</style>
