import { HTTP } from './requests'
import { CAVEONID_BASE } from '../utils/constants'
import { getFingerprint, getOS } from '../utils/helpers'

const cache = {
  me: null,
  orgs: null,
  globalAgreements: null
}

export const API = {
  getMe: async skipCache => {
    if (skipCache || !cache.me) {
      try {
        const response = await HTTP.get(`${CAVEONID_BASE}/users/me`)

        cache.me = response.data
      } catch (error) {
        return { error }
      }
    }

    return cache.me
  },
  updateMe: (key, value) => {
    cache.me[key] = value

    return cache.me
  },
  getOrgs: async skipCache => {
    if (skipCache || !cache.orgs) {
      try {
        const response = await HTTP.get(`/api/organizations`)

        cache.orgs = response.data
      } catch (error) {
        return { error }
      }
    }

    return cache.orgs
  },
  getAgreementResponses: async skipCache => {
    if (skipCache || !cache.agreementResponses) {
      try {
        const response = await HTTP.get(`/api/agreement_responses`)

        cache.agreementResponses = response.data
      } catch (error) {
        return { error }
      }
    }

    return cache.agreementResponses
  },
  getAgreements: async skipCache => {
    if (skipCache || !cache.userAgreements) {
      try {
        const response = await HTTP.get(`/api/agreements`)

        cache.userAgreements = response.data
      } catch (error) {
        return { error }
      }
    }

    return cache.userAgreements
  },
  sendLogin: async data => {
    const fingerprint = await getFingerprint()
    const os = getOS()

    try {
      // TODO remove 'n/a'
      const loginData = {
        email: data.email || 'n/a',
        password: data.password || 'n/a',
        loginToken: data.loginToken || '',
        trustedDeviceToken: data.trustedDeviceToken,
        fingerprint,
        os
      }
      const response = await HTTP.post(`api/auth/login`, loginData)

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  },
  myData: async skipCache => {
    if (skipCache || !cache.myData) {
      try {
        const response = await HTTP.get(`/api/my_data`)

        cache.myData = response.data
      } catch (error) {
        return { error }
      }
    }

    return cache.myData
  },
  clearCache: () => {
    for (const key in cache) {
      cache[key] = null
    }
  },
  _cache: cache
}
