<template>
  <div>
    <div v-if="asText" @click="proxyUser">Proxy user</div>
    <b-button v-else size="sm" variant="white" @click="proxyUser">Proxy user</b-button>    
  </div>
</template>

<script>

import { HTTP } from '../requests/requests'
import { storage } from '../utils/storage'


async function proxyUser(caveonId, proxy) {
  try {
    const url = `/api/auth/proxy/${caveonId}`
    const payload = { proxy }
    const response = await HTTP.post(url, payload)
    return response
  } catch (error) {
    return { error }
  }
}

export default {
  name: 'ProxyUser',
  props: {
    user: {
      type: Object
    },
    asText: {
      type: Boolean
    }
  },
  data() {
    return {
      email: '',
      loading: false,
      loadingProxy: false,
      proxy: false
    }
  },
  methods: {
    async proxyUser() {
      const { data, error } = await proxyUser(this.user.caveon_id, true)

      if (error) {
        this.$bvToast.toast('Failed to proxy user.', {
          title: 'Failed',
          variant: 'danger',
          noAutoHide: true
        })

        return true
      }

      storage.accessToken = null
      storage.refreshToken = null

      const proxyToken = data.accessToken
      window.location.replace(`/login?access_token=${proxyToken}`)
    },
    async unproxyUser() {
      storage.accessToken = null

      return window.location.reload()
    }
  },
  computed: {
    proxyAlertVariant() {
      return this.proxy ? 'success' : 'dark'
    },
    proxyText() {
      return `${this.user.email} ${this.proxy ? 'is' : 'is not'} being proxied`
    },
    proxyButtonText() {
      return this.proxy ? 'Unproxy' : 'Proxy'
    }
  }
}
</script>

<style scoped>
</style>
