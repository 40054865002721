<template>
  <div v-if="loading" class="text-center m-2">
    <b-spinner label="Loading"></b-spinner>
  </div>
  <div v-else>
    <b-button v-b-modal.create-agreement-modal size="sm" :style="{ background: brandColor, borderColor: 'transparent' }">
      <font-awesome-icon icon="plus" />&nbsp;Add an Agreement
    </b-button>
    <AgreementListing
      v-if="agreements"
      class="mt-3"
      :agreements="agreements"
      :organizations="orgs"
      :fields="agreementFields"
      :updateAgreement="updateAgreement"
      title="Agreements"
      :disabled="updating"
    />

    <b-modal id="create-agreement-modal" title="New Agreement" hide-footer>
      <AgreementCreate :addAgreement="addAgreement" :organizations="orgs" />
    </b-modal>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'

  import get from 'lodash.get'

  import AgreementListing from './AgreementListing'
  import AgreementCreate from './AgreementCreate'

  async function updateAgreement(agreement) {
    try {
      const id = get(agreement, 'id', '')
      if (!id) {
        throw new Error('Could not update agreement: No ID.')
      }

      const response = await HTTP.put(`/api/agreements/${id}`, {
        agreement
      })

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Agreements',
    components: {
      AgreementListing,
      AgreementCreate
    },
    data() {
      return {
        orgs: [],
        agreements: null,
        agreementFields: [
          {
            key: 'active',
            sortable: true
          },
          {
            key: 'modified_at',
            sortable: true,
            formatter: date =>
              this.$moment
                .utc(date)
                .local()
                .format('L LT')
          },
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'content',
            sortable: true
          },
          {
            key: 'organization',
            sortable: true
          }
        ],
        loading: true,
        updating: false
      }
    },
    async created() {
      this.orgs = await API.getOrgs()
      this.agreements = await API.getAgreements()
      this.loading = false
    },
    methods: {
      async updateAgreement(agreement) {
        this.updating = true
        const { error, data } = await updateAgreement(agreement)
        if (error) {
          this.$bvToast.toast('Failed to active agreement! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })
          this.updating = false
          return
        }
        const index = this.findAgreementIndex(agreement.id)
        if (index) {
          this.$set(this.agreements, index, data)
        }
        this.updating = false
      },
      addAgreement(agreement) {
        this.$bvModal.hide('create-agreement-modal')
        this.agreements.push(agreement)
      },
      findAgreementIndex(id) {
        return this.agreements.findIndex(agreement => agreement.id === id)
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      }
    }
  }
</script>

<style scoped></style>
