<template>
  <div>
    <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
      <b-navbar-brand href="#" style="color: #fff;">
          <img :src="brandImage" class="logo" />
          Log In</b-navbar-brand>
    </b-navbar>
    <b-container style="max-width: 950px;">
    <b-card class="mt-3 p-4" no-body>
      <b-card-text>
        <b-form @submit.prevent="login" autocomplete="off">
          <div v-if="step == 'email'">
            
            <b-form-group label="Email" label-for="email-input">
              <b-form-input
                id="email-input"
                size="lg"
                autofocus
                required
                type="email"
                v-model="loginForm.email"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-button
                @click="login"
                size="lg"
                class="mt-4"
                style="width: 100%"
                variant="white"
                >Continue</b-button
              >
            </b-form-group>
          </div>
          <div v-if="step == 'password'">
            <b-form-group>
              <div class="row">
                <div class="col">
                  <h5>
                    {{ loginForm.email }}              
                    <font-awesome-icon
                      icon="pen"
                      @click="step = 'email'"
                      style="cursor: pointer; font-size: 12px; vertical-align: text-top; margin-left: 10px;"
                    ></font-awesome-icon>
                    </h5>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label="Password"
              label-for="password-input"
              class="formText"
            >
              <b-form-input
                id="password-input"
                size="lg"
                autofocus
                required
                type="password"
                v-model="loginForm.password"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-button
                @click="login()"
                size="lg"
                style="width: 100%"
                variant="white"
                >Log in</b-button
              >
            </b-form-group>
          </div>
          <b-form-group style="text-align: center;" class="formText">
            <div>OR</div>
          </b-form-group>
          <b-form-group>
            <b-button
              @click="triggerGoogleLogin()"
              variant="white"
              size="lg"
              style="width: 100%"
            >
              <img
                width="20px"
                style="margin-bottom:3px; margin-right:5px"
                alt="Google sign-in"
                src="../assets/google-logo.png"
              />
              Continue with Google
            </b-button>
          </b-form-group>
        </b-form>
      </b-card-text>
    </b-card>
    <div class="row mt-3">
      <router-link to="/reset_password" custom v-slot="{ navigate }">
        <b-button @click="navigate" variant="link" class="col-6 text-left">Reset Password</b-button>
      </router-link>
      <router-link to="/register" custom v-slot="{ navigate }">
        <b-button @click="navigate" variant="link" class="col-6 text-right">Create Account</b-button>
      </router-link>
    </div>
    </b-container>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { CAVEONID_BASE } from '../utils/constants'
  import { HTTP } from '../requests/requests'
  import { getLoginRedirect, setAccessRefreshTokens } from '../utils/helpers'
  import { SESSION } from '../utils/session'
  import { storage } from '../utils/storage'

  async function checkEmail(data) {
    try {
      const response = await HTTP.post(
        `${CAVEONID_BASE}/auth/check_email`,
        data
      )

      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'Login',
    data() {
      return {
        step: 'email',
        loginForm: {
          email: '',
          password: ''
        },
        externalLoginWindow: null
      }
    },
    created() {
      if (storage.accessToken) {
        return this.$router.replace({ path: '/' })
      }

      this.bubbleTokens()

      this.registrationMessage()
    },
    methods: {
      bubbleTokens() {
        this.bubbleLoginToken()
        this.bubbleAccessRefreshTokens()
      },
      bubbleLoginToken() {
        const incomingLoginToken = this.$route.query && this.$route.query.login_token

        if (incomingLoginToken && window.opener) {
          window.opener.postMessage({ loginToken: incomingLoginToken }, window.location.origin)
          return
        }
      },
      async bubbleAccessRefreshTokens() {
        const incomingAccessToken = this.$route.query && this.$route.query.access_token
        const incomingRefreshToken = this.$route.query && this.$route.query.refresh_token

        if (incomingAccessToken || incomingRefreshToken) {
          if (window.opener) {
            window.opener.postMessage({ accessToken: incomingAccessToken, refreshToken: incomingRefreshToken }, window.location.origin)
          } else  {
            await setAccessRefreshTokens(incomingAccessToken, incomingRefreshToken)
            return this.$router.push({ path: '/' })
          }
          return
        }
      },
      async login() {
        if (this.loginForm.email && !this.loginForm.password) {
          const { data } = await checkEmail({ email: this.loginForm.email })

          if (data && data.org_id && data.is_saml_login) {
            this.triggerSamlLogin(data.org_id)
            return
          }

          this.step = 'password'
          return
        }

        if (!this.loginForm.password) {
          return
        }

        storage.email = this.loginForm.email
        const trustedDeviceToken = storage.deviceToken
        const { error, data } = await API.sendLogin({ ...this.loginForm, trustedDeviceToken })
        if (error) {
          this.$bvToast.toast('Failed to login! Please try again.', {
            title: 'Failed',
            variant: 'danger',
            noAutoHide: true
          })
          return
        }

        const url = await getLoginRedirect(data)

        storage.lastActivity = Date.now() / 1000

        return this.$router.push({
          path: url
        })
      },
      triggerGoogleLogin() {
        this.triggerExternalLogin(window.location.origin + '/api/auth/google')
      },
      triggerSamlLogin(orgId) {
        this.triggerExternalLogin(window.location.origin + `/api/auth/saml/${orgId}`)
      },
      async triggerExternalLogin(url) {
        this.externalLoginWindow = window.open(url)

        const externalLoginMessage = async event => {
          if (event.origin !== window.location.origin) return

          const message = event.data

          if (message.loginToken) {
            cleanupExternalLogin()
            storage.email = message.email
            const trustedDeviceToken = storage.deviceToken
            const { data } = await API.sendLogin({ ...message, trustedDeviceToken })
            const url = await getLoginRedirect(data)

            storage.lastActivity = Date.now() / 1000

            return this.$router.push({
              path: url
            })
          }

          if (message.accessToken && message.refreshToken) {
            await setAccessRefreshTokens(message.accessToken, message.refreshToken)

            return this.$router.push({ path: '/' })
          }
        }

        const cleanupExternalLogin = () => {
          this.externalLoginWindow.close()
          window.removeEventListener('message', externalLoginMessage)
          window.removeEventListener('storage', cleanupExternalLogin)
        }

        window.addEventListener('message', externalLoginMessage)
        window.addEventListener('storage', cleanupExternalLogin)
      },
      registrationMessage () {
        const show = sessionStorage.getItem('registered')

        if (show) {
          sessionStorage.removeItem('registered')

          const message = 'A confirmation email has been sent to you.'

          const options = {
            title: 'Registration Successful',
            okTitle: 'Close',
            okVariant: 'white',
            noFade: true
          }

          this.$bvModal.msgBoxOk(message, options)
        }
      }
    },
    computed: {
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      }
    }
  }
</script>

<style scoped>
</style>
