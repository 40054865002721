<template>
  <div>
    <b-navbar toggleable="md" type="dark" class="mb-4" :style="{ background: brandColor }">
      <b-navbar-brand href="#" style="color: #fff;">
        <img :src="brandImage" class="logo" />
        Two Factor Requirement
      </b-navbar-brand>
    </b-navbar>

    <b-container style="max-width: 800px;">
        <b-card class="mt-3 p-4" no-body>
          <b-card-text>
            <h5>Two Factor Authentication is Required</h5>

            <hr />

            <div v-if="info.use_sms_for_two_factor" class="mb-4">
              <div v-if="info.phone_confirmed_at">
                You have a verified phone number and can use SMS for two factor authentication.
              </div>

              <div v-else>
                <b-form-group label="Phone" description='Click "Send Code" to receive an SMS message with a confirmation code.'>
                  <b-input-group>
                    <b-form-input autofocus placeholder="Enter Phone Number" v-model="info.phone" />

                    <b-input-group-append>
                      <b-button @click="sendPhoneCode" :disabled="loading" variant="white">Send Code</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-form-group label="Code">
                  <b-form-input placeholder="Enter Confirmation Code" @input="onCodeChange" :value="code" />
                </b-form-group>
              </div>
            </div>

            <div v-else>
              <div class="instructions">
                <h5>Instructions</h5>
                <ol>
                  <li>
                    Install a two-factor authentication app on your phone (e.g. Google Authenticator or Authy).
                  </li>
                  <li>
                    From the installed app, scan the QR code to add your Caveon Account.
                  </li>
                  <li>
                    Enter the code from the authentication app on your phone.
                  </li>
                </ol>
              </div>

              <p class="mt-4 mb-2">
                Scan this QR code with an authentication app on your phone:
              </p>

              <qrcode-vue
                v-if="otpauth"
                :value="otpauth"
                :size="256"
                level="H"
              />

              <p class="mt-4">
                Or you can manually enter this info into the authentication app on your phone:
                <br />
                <code>{{ info.email }}</code>
                <br />
                <code>{{ secret }}</code>
              </p>

              <b-form-group label="Code">
                <b-form-input autofocus placeholder="Enter Code From Authentication App" @input="onCodeChange" :value="code" />
              </b-form-group>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <b-button @click="switchTwoFactor" :disabled="loading" variant="white" class="mr-2">{{ changeTwoFactorMethodText }}</b-button>

              <b-button @click="submitTwoFactor" :disabled="loading" :style="{ background: brandColor, borderColor: 'transparent' }">Submit</b-button>
            </div>
          </b-card-text>
        </b-card>
    </b-container>
  </div>
</template>

<script>
  import { API } from '../requests/api-cache'
  import { EVENT } from '../utils/eventBus'
  import { HTTP } from '../requests/requests'
  import { SESSION } from '../utils/session'
  import { getLoginRedirect } from '../utils/helpers'

  import jwt_decode from 'jwt-decode'
  import QrcodeVue from 'qrcode.vue'

  async function sendPhoneVerificationCode(phone, loginToken) {
    try {
      const response = await HTTP.post('/api/auth/send_phone_code_login_token', { phone, loginToken })
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  async function submitTwoFactorStatus(code, loginToken) {
    try {
      const response = await HTTP.post('/api/auth/two_factor_status_login_token', { code, loginToken })
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }
 
  async function submitUseSmsStatus(status, loginToken) {
    try {
      const response = await HTTP.post('/api/auth/use_sms_status_login_token', { status, loginToken })
      return { data: response.data }
    } catch (error) {
      return { error }
    }
  }

  export default {
    name: 'TwoFactorAuth',
    components: {
      QrcodeVue
    },
    data() {
      return {
        info: {},
        code: '',
        loginToken: '',
        loading: false
      }
    },
    created() {
      this.loginToken = this.$route.query.loginToken

      try {
        const { statuses } = jwt_decode(this.loginToken)

        this.info = statuses.info
      } catch (error) {
        this.$router.replace({ path: '/login' })
      }
    },
    methods: {
      async sendPhoneCode() {
        this.loading = true

        const { error } = await sendPhoneVerificationCode(this.info.phone, this.loginToken)

        this.loading = false

        let message = 'A confirmation message has been sent to your phone.'
        let variant = 'success'

        if (error) {
            message = 'Failed to send code.'
            variant = 'danger'
        }

        const globalAlertData = {
            message,
            variant
        }

        EVENT.globalAlert(globalAlertData)
      },
      async switchTwoFactor() {
        const status = !this.info.use_sms_for_two_factor

        this.loading = true

        const { error } = await submitUseSmsStatus(status, this.loginToken)

        this.loading = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to switch authentication method.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        this.info.use_sms_for_two_factor = status

        this.code = ''
      },
      async submitTwoFactor() {
        this.loading = true

        const { error } = await submitTwoFactorStatus(this.code, this.loginToken)

        this.loading = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to enable two factor authentication.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        this.submitLoginFlow()
      },
      async submitLoginFlow() {
        this.loading = true

        const { data, error } = await API.sendLogin({ loginToken: this.loginToken })

        this.loading = false

        if (error) {
          const globalAlertData = {
            message: 'Failed to login.',
            variant: 'danger'
          }

          return EVENT.globalAlert(globalAlertData)
        }

        const url = await getLoginRedirect(data)

        return this.$router.push({
          path: url
        })
      },
      onCodeChange(value) {
        this.code = value.toUpperCase()
      }
    },
    computed: {
      changeTwoFactorMethodText() {
        return this.info.use_sms_for_two_factor ? 'Use an authenticator app' : 'Use SMS'
      },
      secret() {
        if (this.info.secret) {
          return this.info.secret.replace(/=/g, '')
        }

        return ''
      },
      otpauth() {
        const email = this.info.email
        const secret = this.secret
        return `otpauth://totp/${email}?secret=${secret}&issuer=Caveon`
      },
      brandColor() {
        return SESSION.branding.color
      },
      brandImage() {
        return require('../assets/' + SESSION.branding.image)
      }
    }
  }
</script>

<style scoped>
  .instructions {
    background: #eee;
    padding: 15px;
    box-sizing: border-box;
  }
  .underline {
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid #efefef;
  }
  .text-green {
    color: green;
  }
  .text-red {
    color: red;
  }
</style>
